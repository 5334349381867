import React from 'react';
import Logo from '../../../components/Logo/Logo'

const LogoHeader: React.FC = () => {
  return (
    // <a
    //   aria-current="page"
    //   className="header-container-nav-logo-styled"
    //   title="My Personal Finances"
    //   target="_self"
    //   href="/"
    // >
      <Logo />
    // </a>
  );
};

export default LogoHeader;
