// AuthContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

// Define the shape of the Auth context
interface AuthContextType {
    isAuthenticated: boolean;
    username: string | null;
    email: string | null;
    userId: number | null;
    userCreatedAt: string | null;
    login: (username: string, email: string, userId: number, userCreatedAt: string) => void;
    logout: () => void;
}


// Create the Auth context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Create a provider component
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [username, setUsername] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [userId, setUserId] = useState<number | null>(null);
    const [userCreatedAt, setUserCreatedAt] = useState<string | null>(null);

    useEffect(() => {
        // Check localStorage to see if the user is authenticated
        const storedUsername = localStorage.getItem('username');
        const storedIsAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
        const storedEmail = localStorage.getItem('email');
        const storedUserId = localStorage.getItem('userId');
        const storedUserCreatedAt = localStorage.getItem('userCreatedAt');

        if (storedIsAuthenticated && storedUsername) {
            setIsAuthenticated(true);
            setUsername(storedUsername);
            setEmail(storedEmail);
            setUserId(storedUserId ? Number(storedUserId) : null);
            setUserCreatedAt(storedUserCreatedAt);

        }
    }, []);

    const login = (user: string, userEmail: string, userId: number, userCreatedAt:string) => {
        setIsAuthenticated(true);
        setUsername(user);
        setEmail(userEmail);
        setUserId(userId);
        setUserCreatedAt(userCreatedAt);

        localStorage.setItem('username', user);
        localStorage.setItem('email', userEmail);
        localStorage.setItem('userId', userId.toString());
        localStorage.setItem('userCreatedAt', userCreatedAt.toString());
        localStorage.setItem('isAuthenticated', 'true');
        navigate('/'); // Redirect to home page

    };

    const logout = () => {
        setIsAuthenticated(false);
        setUsername(null);
        localStorage.removeItem('username');
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('email');
        localStorage.removeItem('userId');
        localStorage.removeItem('userCreatedAt');

        navigate('/'); // Redirect to home page

    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, username, email, userId, userCreatedAt, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

// Create a custom hook for easier access to the context
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
