//AppContext
import React, {
  createContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

// Define the shape of the context's value
interface AppContextType {
  isMobileMenuBarOpen: boolean;
  setIsMobileMenuBarOpen: Dispatch<SetStateAction<boolean>>;
  isMobile1100: boolean;
  setIsMobile1100: Dispatch<SetStateAction<boolean>>;
  isMobile625: boolean;
  setIsMobile625: Dispatch<SetStateAction<boolean>>;
}

// Initialize the context with undefined to enforce provider usage
export const AppContext = createContext<AppContextType | undefined>(undefined);

// Define props for the AppProvider component
interface AppProviderProps {
  children: ReactNode;
}

// Create the provider component
export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [isMobileMenuBarOpen, setIsMobileMenuBarOpen] =
    useState<boolean>(false);
  const [isMobile625, setIsMobile625] = useState(window.innerWidth <= 625);
  const [isMobile768, setIsMobile768] = useState(window.innerWidth <= 768);
  const [isMobile875, setIsMobile875] = useState(window.innerWidth <= 875);
  const [isMobile950, setIsMobile950] = useState(window.innerWidth <= 950);
  const [isMobile1100, setIsMobile1100] = useState(window.innerWidth <= 1100);

  const handleResize = () => {
    setIsMobile625(window.innerWidth <= 625);
    setIsMobile768(window.innerWidth <= 768);
    setIsMobile875(window.innerWidth <= 875);
    setIsMobile950(window.innerWidth <= 950);
    setIsMobile1100(window.innerWidth <= 1100);
  };

  // Set up event listener on mount to track window size changes
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <AppContext.Provider
      value={{
        isMobileMenuBarOpen,
        setIsMobileMenuBarOpen,
        isMobile1100,
        setIsMobile1100,
        isMobile625,
        setIsMobile625,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
