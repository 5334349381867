// src/components/PasswordStrengthIndicator.tsx
import React from 'react';
import './PasswordStrengthIndicator.css'; // Import the CSS file for styles

interface PasswordStrengthIndicatorProps {
  score: number;
}

const PasswordStrengthIndicator: React.FC<PasswordStrengthIndicatorProps> = ({ score }) => {
  const getColor = (score: number) => {
    switch (score) {
      case 0:
      case 1:
        return 'red'; // Weak
      case 2:
        return 'orange'; // Fair
      case 3:
        return 'yellow'; // Good
      case 4:
        return 'green'; // Strong
      default:
        return 'black'; // Fallback
    }
  };

  const strengthPercentage = (score / 4) * 100; // Calculate strength percentage

  return (
    <div className="strength-indicator">
      <div className="strength-background"> {/* Background for the bar */}
        <div
          className="strength-bar"
          style={{
            width: `${strengthPercentage}%`,
            backgroundColor: getColor(score),
          }}
        />
      </div>
      <div className="strength-text">
        {['Weak', 'Fair', 'Good', 'Strong', 'Very Strong'][score]}
      </div>
    </div>
  );
};

export default PasswordStrengthIndicator;
