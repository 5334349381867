import React from 'react';

const Logo: React.FC = () => {
    return (
        <div style={{ width: '15.882352941176471rem', height: '6rem', overflow: 'hidden' }}>
            <img 
                src="/logo_2024_10.png" // Directly referencing the image from the public directory
                alt="Pocket Numbers Logo" 
                style={{ width: '100%', height: '100%', objectFit: 'contain' }} 
            />
        </div>
        
    );
};

export default Logo;
