import React from 'react';
import { Link } from 'react-router-dom';
import './404Page.css'; // Optional: Create a separate CSS file for styling
import MobileSideBar from '../../components/MobileSideBar/MobileSideBar';

const NotFound: React.FC = () => {
  return (
    <div>
      <MobileSideBar />
      <section className="full-screen-main-section">
        <div className="not-found">
          <h1>404 - Page Not Found</h1>
          <p>Sorry, the page you are looking for does not exist.</p>
          <Link to="/">Go Back to Home!</Link>
        </div>
      </section>
    </div>
  );
};

export default NotFound;
