import React from 'react';

interface SelectDropdownProps {
  options: string[]; // List of options to display
  value: string; // Selected value
  onChange: (value: string) => void; // Change handler function
  required?: boolean; // Optional required prop
  className?: string; // Optional custom class for styling
  placeholder?: string; // Optional placeholder
  id?: string; // Optional ID for external label association
}

const SelectDropdownField: React.FC<SelectDropdownProps> = ({
  options,
  id,
  value,
  onChange,
  required = false,
  className = 'form-control custom-select',
  placeholder = 'Select an option',
}) => {
  return (
    <select
      id={id}
      className={className}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      required={required}
    >
      {/* <option value="">{placeholder}</option> */}
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export default SelectDropdownField;
