import React, {useContext} from 'react';
import { ProgressBar } from 'react-bootstrap';
import { AppContext } from '../../../AppContext';
import './ScoreBar.css'; // Ensure this CSS file is created

interface ScoreBarProps {
    score: number; // Score should be between 0 and 1
    isOverall?: boolean; // Optional prop for overall score
    showPerformanceMessages?: boolean; // Optional prop to show performance messages
}

const ScoreBar: React.FC<ScoreBarProps> = ({ score, isOverall = false, showPerformanceMessages = true }) => {
    const appContext = useContext(AppContext);
    if (!appContext) {
      throw new Error('Header must be used within an AppProvider');
    }
    const {
      isMobileMenuBarOpen,
      setIsMobileMenuBarOpen,
      isMobile1100,
      setIsMobile1100,
      isMobile625,
      setIsMobile625,
    } = appContext;

    
    const percentage = score * 100;

    const getGradientColor = (score: number) => {
        // Define colors using RGB
        const badRed = { r: 255, g: 70, b: 70 }; // Solid Red
        const neutralYellow = { r: 255, g: 215, b: 0 }; // Bright Yellow
        const goodGreen = { r: 0, g: 150, b: 50 }; // Solid Green
        const middlePoint1 = 0.25; // Below this point is solid red
        const middlePoint2 = 0.5;  // Transition from yellow to green starts here
        const middlePoint3 = 0.9;  // Above this point is solid green

        let r, g, b;

        // Determine the gradient based on the score
        if (score <= middlePoint1) {
            // Solid red
            r = badRed.r;
            g = badRed.g;
            b = badRed.b;
        } else if (score <= middlePoint2) {
            // Interpolate between bad red and neutral yellow
            const ratio = (score - middlePoint1) / (middlePoint2 - middlePoint1); // Scale up to 1
            r = Math.round(badRed.r + ratio * (neutralYellow.r - badRed.r));
            g = Math.round(badRed.g + ratio * (neutralYellow.g - badRed.g));
            b = Math.round(badRed.b + ratio * (neutralYellow.b - badRed.b));
        } else if (score <= middlePoint3) {
            // Interpolate between neutral yellow and good green
            const ratio = (score - middlePoint2) / (middlePoint3 - middlePoint2); // Scale from 0 to 1
            r = Math.round(neutralYellow.r + ratio * (goodGreen.r - neutralYellow.r));
            g = Math.round(neutralYellow.g + ratio * (goodGreen.g - neutralYellow.g));
            b = Math.round(neutralYellow.b + ratio * (goodGreen.b - neutralYellow.b));
        } else {
            // Full green
            r = goodGreen.r;
            g = goodGreen.g;
            b = goodGreen.b;
        }

        return `rgb(${r}, ${g}, ${b})`;
    };

    const gradientColor = getGradientColor(score);
    const isGreat = score > 0.9; // Check if the score is great
    const isExceptional = score > 0.95; // Check if the score is exceptional

    return (
        <div className={`score-bar-container ${isGreat ? 'great' : ''} ${isExceptional ? 'exceptional' : ''}`}>
            <ProgressBar now={percentage} style={{ height: '1.5rem' }}>
                <div
                    style={{
                        height: '100%', // Fill the full height of the progress bar
                        width: `${percentage}%`, // Set width based on score
                        backgroundColor: gradientColor, // Apply gradient color to front
                        borderRadius: '5px', // Optional: for rounded corners
                        display: 'flex', // Use flexbox for centering
                        justifyContent: 'center', // Center horizontally
                        alignItems: 'center', // Center vertically
                    }}
                >
                    <span
                        style={{
                            color: '#fff', // Set the text color for visibility
                            fontWeight: 'bold', // Optional: make label bold
                        }}
                    >
                        {`${percentage.toFixed(0)}%`}
                    </span>
                </div>
            </ProgressBar>
            {showPerformanceMessages && (
                <>
                    {isExceptional && (
                        <div className={`exceptional-message ${isMobile1100 ? 'mobile' : ''}`} >
                            <span role="img" aria-label="celebration">🎉</span> 
                            {!isMobile1100 ? "Exceptional Performance!" : "Exceptional!"}
                        </div>
                    )}
                    {isGreat && !isExceptional && ( // Only show this if not exceptional
                        <div className={`great-message ${isMobile1100 ? 'mobile' : ''}`} >
                            {/* <span role="img" aria-label="star">🌟</span> Great Performance! */}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ScoreBar;
