// src/Home.tsx
import './HomeMainSection.css';
import './HomeFeatureSection.css';
import './HomeFAQSection.css';
// React
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useContext } from 'react';
// Libraries
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Context
import { useAuth } from '../Login/AuthContext';
import { AppContext } from '../../AppContext';
// Components
import MobileSideBar from '../../components/MobileSideBar/MobileSideBar';
import Button from '../../components/Button/Button';
import ButtonDark from '../../components/Button/ButtonDark';
import SVGSmartPersonalFinanceGraph from './svg/SmartPersonalFinanceGraph';
import CalculatorsGraph from './svg/CalculatorsGraph';
import BenchmarkGraph from './svg/BenchmarkGraph';
import Toast from './../../components/ToastAlert/Toast';

const Home: React.FC = () => {
  // Context
  const { isAuthenticated, username, logout } = useAuth();
  const appContext = useContext(AppContext);
  if (!appContext) {
    throw new Error('Header must be used within an AppProvider');
  }
  const {
    isMobileMenuBarOpen,
    setIsMobileMenuBarOpen,
    isMobile1100,
    setIsMobile1100,
    isMobile625,
    setIsMobile625,
  } = appContext;

  // States
  const navigate = useNavigate();

  // Handle
  const handleNavigation = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const [showToast, setShowToast] = useState(false);

  const handleHelloEmailCopy = () => {
    const email = 'hello@pocketnumbers.com';

    navigator.clipboard
      .writeText(email)
      .then(() => {
        setShowToast(true); // Show the toast after successful copy

        // Hide toast after 3 seconds
        const timeoutId = setTimeout(() => setShowToast(false), 3000);

        // Clear the timeout if the component unmounts or a new copy is triggered
        return () => clearTimeout(timeoutId);
      })
      .catch((err) => {
        console.error('Error copying text: ', err);
      });
  };

  return (
    <>
      <MobileSideBar />
      <main className="home-main">
        <section
          className={`home-main-section ${isMobile1100 ? 'mobile' : ''}`}
        >
          <div
            className={`home-main-section-title ${
              isMobile1100 ? 'mobile' : ''
            }`}
          >
            {/* <h1>See were you stand, shape where you’re going</h1> */}
            {isMobile1100 ? (
              <>
                <h1>Understand Your Finances.</h1> <h1>Build Your Future</h1>
              </>
            ) : (
              <h1>Understand Your Finances. Build Your Future</h1>
            )}

            <div className="home-main-section-title-description-box">
              <div className="home-main-section-title-description-box-text">
                <p>
                  Gain personalized insights, compare your finances to country
                  and age-based statistics, and get <strong>smart</strong>,{' '}
                  <strong>actionable</strong> steps to improve your{' '}
                  <strong>financial well-being</strong>.
                </p>
              </div>
            </div>
          </div>

          <div
            className={`home-main-section-container ${
              isMobile1100 ? 'mobile' : ''
            }`}
          >
            <div className="home-main-section-container-subcontainer">
              <div className="home-main-section-container-element">
                <h1>
                  <strong>Simplify your Personal Finances</strong>
                </h1>
              </div>
              {!isAuthenticated ? (
                <>
                  <Button
                    text="Sign up for free"
                    handleButtonClick={() => handleNavigation('/signup')}
                    showArrow={true}
                  />
                </>
              ) : (
                <Button
                  text="Income Livability Calculator"
                  handleButtonClick={() =>
                    handleNavigation('/calculators/income-livability')
                  }
                />
              )}
            </div>
            <div className="home-main-section-container-element-text-box">
              <p>
                Experience a powerful personal finance tool that analyzes your
                financial data, runs realistic simulations, and provides
                tailored comparisons against relevant statistics for your
                country and age group. Using advanced algorithms and proprietary
                software, this tool delivers precise scores, insightful
                predictions, and actionable recommendations to help you
                understand, assess, and improve your financial health.
              </p>
              <p>All in one place.</p>
            </div>
            <img
              className={`home-main-section-container-image  ${
                isMobile1100 ? 'mobile' : ''
              }`}
              alt="example feature 1"
              src={`${
                isMobile625 ? '/home-img-1-mobile.png' : '/home-img-1.png'
              }`}
            ></img>
          </div>
        </section>

        <section
          className={`home-feature-section ${isMobile1100 ? 'mobile' : ''}`}
        >
          <div
            className={`home-feature-section-container-box ${
              isMobile1100 ? 'mobile' : ''
            }`}
          >
            <div
              className={`home-feature-section-container-box-feature-container ${
                isMobile1100 ? 'mobile' : ''
              }`}
            >
              <div className="feature-container-imagebox">
                <div className="feature-container-imagebox-image">
                  <SVGSmartPersonalFinanceGraph />
                </div>
                <div className="feature-container-imagebox-text">
                  <h2>Smart Personal Finances</h2>
                  Gain a clear understanding of your finances with an accurate
                  score, and compare it to relevant statistics tailored to your
                  lifestyle, circumstances, and financial decisions.
                  <p />
                  Take control of your financial future.
                </div>
                <div className="feature-container-imagebox-button">
                  <Button
                    className="dark small"
                    text="Understand Your Finances"
                    handleButtonClick={() => {
                      if (isAuthenticated) {
                        handleNavigation('/feature/smart-personal-finances');
                      } else {
                        handleNavigation('/login');
                      }
                    }}
                    showArrow={true}
                    disabled={false}
                  />
                </div>
              </div>
            </div>
            <div
              className={`home-feature-section-container-box-feature-container ${
                isMobile1100 ? 'mobile' : ''
              }`}
            >
              <div className="feature-container-imagebox var2">
                <div className="feature-container-imagebox-image">
                  <CalculatorsGraph />
                </div>
                <div className="feature-container-imagebox-text">
                  <h2>Financial Calculators</h2>
                  Use our Calculators to determine Currency Conversions or to
                  calculate income livability scores tailored to your age and
                  country.
                </div>
                <div
                  className="feature-container-imagebox-button"
                  style={{ paddingBottom: '0px' }}
                >
                  <Button
                    className="dark small"
                    text="Convert Currencies"
                    handleButtonClick={() =>
                      handleNavigation('/calculators/fx')
                    }
                    showArrow={true}
                  />
                </div>
                <div
                  className="feature-container-imagebox-button"
                  style={{ paddingTop: '8px' }}
                >
                  <Button
                    className="dark small"
                    text="Calculate your Income Livability"
                    handleButtonClick={() =>
                      handleNavigation('/calculators/income-livability')
                    }
                    showArrow={true}
                  />
                </div>
              </div>
            </div>
            <div
              className={`home-feature-section-container-box-feature-container ${
                isMobile1100 ? 'mobile' : ''
              }`}
            >
              <div className="feature-container-imagebox var3">
                <div className="feature-container-imagebox-image">
                  <BenchmarkGraph />
                </div>
                <div className="feature-container-imagebox-text">
                  <h2>Benchmarks</h2>
                  Leverage our advanced algorithms to explore relevant
                  comparisons and benchmarks for key personal finance indicators
                  by country, age, and financial profile. See how income
                  livability varies, for example, for a fast food worker in
                  different parts of the world.
                </div>
                <div className="feature-container-imagebox-button">
                  <Button
                    className="dark small"
                    text="Explorer Global Benchmarks"
                    handleButtonClick={() =>
                      handleNavigation('/benchmarks/income-livability')
                    }
                    showArrow={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={`home-faq-section ${isMobile1100 ? 'mobile' : ''}`}>
          <div
            className={`home-faq-section-container-box ${
              isMobile1100 ? 'mobile' : ''
            }`}
          >
            <div className="home-faq-section-container-box-faq-title">
              <span className="home-faq-section-container-box-faq-title-left-qm">
                ?
              </span>
              <h1>FAQ</h1>
              <span className="home-faq-section-container-box-faq-title-right-qm">
                ?
              </span>
            </div>
            <div className="home-faq-section-container-box-faq-description">
              <p>
                Got more questions? Feel free to send us an email to{' '}
                <span
                  onClick={handleHelloEmailCopy}
                  style={{
                    color: '#3498db',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                >
                  hello@pocketnumbers.com
                </span>
              </p>
              {showToast && (
                <Toast message="Email copied to clipboard!" className="light" />
              )}
            </div>

            {/* Accordion FAQ */}
            <Accordion className="home-faq-section-accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <div className="home-faq-section-accordion text">
                    <strong>
                      Is <span style={{ color: '#3E5C76' }}>Pocket</span>
                      <span style={{ color: '#1D2D44' }}>Numbers</span> Free?
                    </strong>
                  </div>
                </Typography>{' '}
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="home-faq-section-accordion text">
                    This app is 100% free. Our priority is to deliver a
                    high-quality, simple personal finance analysis experience
                    that’s tailored to real people. In the future, we may
                    introduce additional paid features, but the core
                    functionality will always remain free to ensure that
                    everyone has access to the most accurate and personalized
                    financial insights.
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="home-faq-section-accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <div className="home-faq-section-accordion text">
                    <strong>
                      What is the main goal of{' '}
                      <span style={{ color: '#3E5C76' }}>Pocket</span>
                      <span style={{ color: '#1D2D44' }}>Numbers</span>?
                    </strong>
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="home-faq-section-accordion text">
                    Our focus is on delivering real, simple personal finance
                    insights tailored to real people all around the world. We’ve
                    designed the app to adapt to individual financial
                    conditions, ensuring accurate, personalized financial
                    understanding that is adaptable to one's personal situation.
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="home-faq-section-accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <div className="home-faq-section-accordion text">
                    <strong>How do I interpret the financial scores?</strong>
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="home-faq-section-accordion text">
                    The financial scores provide a snapshot of financial health
                    by taking into account factors such as income, expenses,
                    savings, investments, debt management, personal conditions
                    such as country, age or dependants, lifestyle. A higher
                    score typically indicates a stronger financial position,
                    stronger personal finances management and more financial
                    freedom. On the other hand, lower scores indicate tighter
                    financial margins, which can result in less disposable
                    income after covering essential living expenses.
                    <br />
                    The score is ultimately presented as a percentage (0-100%)
                    and can be interpreted as follows:
                    <br />
                    - 95%+ (A+): Exceptional
                    <br />
                    - 90-94% (A): Excellent
                    <br />
                    - 80-89% (B): Great
                    <br />
                    - 70-79% (C): Good
                    <br />
                    - 60-69% (D): Okay
                    <br />
                    - 50-59% (E): Poor
                    <br />- Below 50% (F): Very Poor
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="home-faq-section-accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <div className="home-faq-section-accordion text">
                    <strong>
                      Do I need to provide a lot of personal financial
                      information?
                    </strong>
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="home-faq-section-accordion text">
                    Not at all, you only need to provide a few key details such
                    as your age, country, and income to get started. These basic
                    inputs are enough to offer you an initial relevant
                    personalized financial analysis. Your privacy and security
                    are our top priorities, and we ensure that all your data is
                    handled safely and <strong>is not stored</strong>. While we
                    strive to deliver the most accurate insights, you also have
                    the option to share additional, optional data points to
                    improve the accuracy of your financial analysis. However,
                    the choice is always yours.
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </section>
      </main>
    </>
  );
};

export default Home;
