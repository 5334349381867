import './MobileSideBar.css';
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext'; // Assuming AppContext handles open/close globally
import { useAuth } from '../../pages/Login/AuthContext';

const MobileSidebar: React.FC = () => {
  const appContext = useContext(AppContext);
  const { isAuthenticated, username, logout } = useAuth();
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (!appContext) {
    throw new Error('MobileSidebar must be used within an AppProvider');
  }

  const { isMobileMenuBarOpen, setIsMobileMenuBarOpen } = appContext;

  const closeSidebar = () => {
    setIsMobileMenuBarOpen(false); // Close sidebar when clicked outside
  };

  const handleMenuSideBarLogout = async () => {
    await new Promise((resolve) => setTimeout(resolve, 200));
    logout();
    navigate('/');
    setIsMobileMenuBarOpen(false);
  };

  const handleNavigateDelayed = (path: string) => {
    return async () => {
      await new Promise((resolve) => setTimeout(resolve, 200));
      navigate(path);
      setIsMobileMenuBarOpen(false);
    };
  };

  // Dynamically calculate the 'top' value based on scroll position
  const sidebarStyle = {
    top: `${Math.max(-scrollPosition + 100, 0)}px`, // Adjust this value to fit your needs
  };

  return (
    <>
      {/* Gray overlay that closes the sidebar when clicked outside */}
      {isMobileMenuBarOpen && <div className="sidebar-overlay" onClick={closeSidebar}></div>}

      <div
        className={`mobile-sidebar ${isMobileMenuBarOpen ? 'open' : ''}`}
        onClick={closeSidebar}
        style={sidebarStyle}
      >
        <div className="sidebar-content" onClick={(e) => e.stopPropagation()}>
          <h2 className="sidebar-title">Menu</h2>
          <ul className="sidebar-sections">
            <li>
              <a className="sidebar-sections-title-li">Calculators</a>
            </li>
            <li>
              <a
                onClick={handleNavigateDelayed('/calculators/fx')}
                className="sidebar-link"
              >
                {'> '}Currency Converter 💱
              </a>
            </li>
            <li>
              <a
                onClick={handleNavigateDelayed('/calculators/income-livability')}
                className="sidebar-link"
              >
                {'> '}Income Livability 🏠
              </a>
            </li>
            <li>
              <a className="sidebar-sections-title-li">Benchmarks & Insights</a>
            </li>
            <li>
              <a
                onClick={handleNavigateDelayed('/benchmarks/income-livability')}
                className="sidebar-link"
              >
                {'> '}Income Livability Index 🌍
              </a>
            </li>

            <li>
              <a className="sidebar-sections-title-li">Settings</a>
            </li>
            {!isAuthenticated && (
              <li>
                <a
                  onClick={handleNavigateDelayed('/login')}
                  className="sidebar-link"
                >
                  {'> '}Login
                </a>
              </li>
            )}
            {!isAuthenticated && (
              <li>
                <a
                  onClick={handleNavigateDelayed('/signup')}
                  className="sidebar-link"
                >
                  {'> '}Signup
                </a>
              </li>
            )}
            {isAuthenticated && (
              <li>
                <a onClick={handleMenuSideBarLogout} className="sidebar-link">
                  {'> '}Logout
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default MobileSidebar;
    