import React from 'react';

const BenchmarkGraph: React.FC = () => {
  const COLORS = {
    lightGray: '#8CA0BA',
    mediumGray: '#3E5C76',
    lightBlue: '#B7C8E1',
    darkBlue: '#3E5C76',
    primaryBlue: '#8CA0BA',
    white: '#fff',
    extraLightGray: '#e5e6eb',
    darkBluestroke: '#0D1321',
    accent2: '#F9D6C8',
    accent3: '#F5BAA3',
    accent4: '#F19F7E',
    accent5: '#EF8354',
  };

  return (
    <svg
      width="200px"
      height="200px"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      className="iconify iconify--fxemoji"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill={COLORS.lightGray}
        d="M478.685 44.5H32a4.5 4.5 0 0 1 0-9h446.685a4.5 4.5 0 0 1 0 9zm4.5 44.918a4.5 4.5 0 0 0-4.5-4.5H32a4.5 4.5 0 0 0 0 9h446.685a4.5 4.5 0 0 0 4.5-4.5zm0 49.418a4.5 4.5 0 0 0-4.5-4.5H32a4.5 4.5 0 0 0 0 9h446.685a4.5 4.5 0 0 0 4.5-4.5zm0 49.418a4.5 4.5 0 0 0-4.5-4.5H32a4.5 4.5 0 0 0 0 9h446.685a4.5 4.5 0 0 0 4.5-4.5zm0 49.418a4.5 4.5 0 0 0-4.5-4.5H32a4.5 4.5 0 0 0 0 9h446.685a4.5 4.5 0 0 0 4.5-4.5zm0 49.418a4.5 4.5 0 0 0-4.5-4.5H32a4.5 4.5 0 0 0 0 9h446.685a4.5 4.5 0 0 0 4.5-4.5zm0 49.418a4.5 4.5 0 0 0-4.5-4.5H32a4.5 4.5 0 0 0 0 9h446.685a4.5 4.5 0 0 0 4.5-4.5zm0 49.418a4.5 4.5 0 0 0-4.5-4.5H32a4.5 4.5 0 0 0 0 9h446.685a4.5 4.5 0 0 0 4.5-4.5z"
      ></path>
      <path
        fill={COLORS.accent4}
        d="M183.96 483.418H82.669V137.39c0-12.364 10.023-22.387 22.387-22.387h56.517c12.364 0 22.387 10.023 22.387 22.387v346.028z"
      ></path>
      <path
        fill={COLORS.accent3}
        d="M318.431 483.418H217.14V296.187c0-12.364 10.023-22.387 22.387-22.387h56.517c12.364 0 22.387 10.023 22.387 22.387v187.231z"
      ></path>
      <path
        fill={COLORS.accent5}
        d="M452.902 483.418h-101.29V80.91c0-12.364 10.023-22.387 22.387-22.387h56.517c12.364 0 22.387 10.023 22.387 22.387v402.508z"
      ></path>
      <path
        fill={COLORS.mediumGray}
        d="M478.685 489.418H32a7 7 0 0 1-7-7V40a7 7 0 1 1 14 0v435.418h439.685a7 7 0 1 1 0 14z"
      ></path>
    </svg>
  );
};

export default BenchmarkGraph;
