import React from 'react';

interface ArrowProps {
  width?: string; // Width of the arrow
  height?: string; // Height of the arrow
  color?: string; // Color of the arrow
  className?: string; // Optional additional class names
}

const Arrow: React.FC<ArrowProps> = ({
  width = '1em', // Default width is 1em
  height = '1em', // Default height is 1em
  color = 'currentColor', // Default color is currentColor, which inherits text color
  className = '', // Default className is an empty string
}) => {
  return (
    <svg
      className={`arrow ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 256 256"
      aria-label="right arrow"
    >
      <rect width="256" height="256" fill="none"></rect>
      <line
        x1="40"
        y1="128"
        x2="216"
        y2="128"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="24"
      ></line>
      <polyline
        points="144 56 216 128 144 200"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="24"
      ></polyline>
    </svg>
  );
};

export default Arrow;
