import React from 'react';

const SmartPersonalFinanceGraph: React.FC = () => {
  return (
    // <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    // <!-- License: CC0. Made by SVG Repo: https://www.svgrepo.com/svg/7296/finances -->
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300.16 300.16" // The coordinate system of the SVG content
      width="180" // Scaled down to 150px width
      height="180" // Scaled down to 150px height
      preserveAspectRatio="xMinYMin" // Align to top-left corner
      // enableBackground="new 0 0 300.16 300.16"
    >
      <g>
        <g>
          <path
            style={{ fill: '#3E5C76' }}
            d="M4.841,24.206v130.715h125.873V111.35h67.778V24.206H4.841z M36.31,135.556
                c-4.009,0-7.262-3.253-7.262-7.262c0-4.009,3.253-7.262,7.262-7.262s7.262,3.253,7.262,7.262
                C43.572,132.303,40.318,135.556,36.31,135.556z M70.199,101.667c-4.009,0-7.262-3.253-7.262-7.262s3.253-7.262,7.262-7.262
                s7.262,3.253,7.262,7.262S74.207,101.667,70.199,101.667z M108.929,121.032c-4.009,0-7.262-3.253-7.262-7.262
                s3.253-7.262,7.262-7.262s7.262,3.253,7.262,7.262S112.938,121.032,108.929,121.032z M167.024,58.095
                c-4.009,0-7.262-3.253-7.262-7.262s3.253-7.262,7.262-7.262s7.262,3.253,7.262,7.262S171.033,58.095,167.024,58.095z"
          />
          <path
            style={{ fill: '#0D1321' }}
            d="M280.795,125.873H145.239v58.095h135.556L280.795,125.873L280.795,125.873z M271.112,174.286
                H154.921v-38.73h116.191V174.286z"
          />
          <path
            style={{ fill: '#0D1321' }}
            d="M183.969,193.651h-38.73v38.73h38.73V193.651z M174.286,222.699h-19.365v-19.365h19.365V222.699z"
          />
          <path
            style={{ fill: '#0D1321' }}
            d="M183.969,242.064h-38.73v38.73h38.73V242.064z M174.286,271.112h-19.365v-19.365h19.365V271.112z"
          />
          <path
            style={{ fill: '#0D1321' }}
            d="M193.651,232.382h38.73v-38.73h-38.73V232.382z M203.334,203.334h19.365v19.365h-19.365V203.334z"
          />
          <path
            style={{ fill: '#0D1321' }}
            d="M125.873,106.508v48.413H0v24.206h27.983L4.938,294.37l9.494,1.903l23.427-117.145h58.967v116.191
                h9.683V179.128h19.365V300.16H300.16V106.508H125.873z M9.683,169.445v-4.841h116.191v4.841H9.683z M290.477,290.477H135.556
                V116.191h154.921V290.477z"
          />
          <path
            style={{ fill: '#0D1321' }}
            d="M193.651,280.795h38.73v-38.73h-38.73V280.795z M203.334,251.747h19.365v19.365h-19.365V251.747z"
          />
          <path
            style={{ fill: '#0D1321' }}
            d="M242.064,232.382h38.73v-38.73h-38.73L242.064,232.382L242.064,232.382z M251.747,203.334h19.365
                v19.365h-19.365V203.334z"
          />
          <path
            style={{ fill: '#0D1321' }}
            d="M242.064,280.795h38.73v-38.73h-38.73L242.064,280.795L242.064,280.795z M251.747,251.747h19.365
                v19.365h-19.365V251.747z"
          />
          <path
            style={{ fill: '#0D1321' }}
            d="M203.334,0H0v24.206h203.334V0z M193.651,14.524H9.683V9.683h183.969L193.651,14.524
                L193.651,14.524z"
          />
          <path
            style={{ fill: '#0D1321' }}
            d="M167.024,62.937c6.676,0,12.103-5.432,12.103-12.103S173.7,38.73,167.024,38.73
                s-12.103,5.432-12.103,12.103c0,1.685,0.353,3.292,0.978,4.754l-42.928,46.83c-1.268-0.46-2.619-0.75-4.042-0.75
                c-3.244,0-6.177,1.297-8.351,3.384l-18.31-10.985c-0.184-6.516-5.509-11.764-12.069-11.764c-6.676,0-12.103,5.432-12.103,12.103
                c0,1.554,0.32,3.031,0.857,4.401L40.71,117.048c-1.37-0.537-2.847-0.857-4.401-0.857c-6.676,0-12.103,5.432-12.103,12.103
                c0,6.671,5.427,12.103,12.103,12.103s12.103-5.432,12.103-12.103c0-1.554-0.32-3.031-0.857-4.401l18.242-18.242
                c1.37,0.537,2.847,0.857,4.401,0.857c3.244,0,6.177-1.297,8.351-3.384l18.31,10.985c0.184,6.516,5.509,11.764,12.069,11.764
                c6.676,0,12.103-5.432,12.103-12.103c0-1.685-0.353-3.292-0.978-4.754l42.928-46.83C164.25,62.646,165.601,62.937,167.024,62.937z
                 M167.024,48.413c1.331,0,2.421,1.084,2.421,2.421c0,1.336-1.089,2.421-2.421,2.421s-2.421-1.084-2.421-2.421
                C164.604,49.497,165.693,48.413,167.024,48.413z M36.31,130.715c-1.331,0-2.421-1.084-2.421-2.421
                c0-1.336,1.089-2.421,2.421-2.421s2.421,1.084,2.421,2.421C38.73,129.63,37.641,130.715,36.31,130.715z M70.199,96.826
                c-1.331,0-2.421-1.084-2.421-2.421s1.089-2.421,2.421-2.421c1.331,0,2.421,1.084,2.421,2.421S71.53,96.826,70.199,96.826z
                 M108.929,116.191c-1.331,0-2.421-1.084-2.421-2.421s1.089-2.421,2.421-2.421s2.421,1.084,2.421,2.421
                S110.26,116.191,108.929,116.191z"
          />
        </g>
      </g>
    </svg>
  );
};

export default SmartPersonalFinanceGraph;
