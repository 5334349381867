import React from 'react';
import { useEffect, useState } from 'react';
import './GeneralFormRow.css';
import { FaInfoCircle } from 'react-icons/fa';
import SelectDropdownField from './../../FormFields/FormDropdownField';
import { currencyOptions } from './../../../Common/Currencies';
import { allCountries } from './../../../Common/Countries';
import { Tooltip } from 'react-tooltip';

// Function to get currency symbol
export const getCurrencySymbol = (currencyCode: string) => {
  const currency = currencyOptions.find(
    (option) => option.code === currencyCode
  );
  return currency ? currency.symbol : ''; // Return symbol if found, else return empty string
};

// Function to get currency full name
export const getCurrencyName = (currencyCode: string) => {
  const currency = currencyOptions.find(
    (option) => option.code === currencyCode
  );
  return currency ? currency.name : ''; // Return name if found, else return empty string
};

interface GeneralFormRowProps {
  age: number | '';
  setAge: React.Dispatch<React.SetStateAction<number | ''>>;
  country: string;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
  currency: string;
  setCurrency: React.Dispatch<React.SetStateAction<string>>;
  grossValidCountries: string[];
  incomeType: string;
}

const GeneralFormRow: React.FC<GeneralFormRowProps> = ({
  age,
  setAge,
  country,
  setCountry,
  currency,
  setCurrency,
  grossValidCountries,
  incomeType,
}) => {
  const sortedvalidCountries = Array.from(new Set(allCountries.sort()));

  // Function to extract unique currency options
  const getUniqueCurrencyFields = (
    options: { code: string; symbol: string }[]
  ) => {
    const uniqueCurrencies = Array.from(
      new Set(options.map((option) => option.code))
    );
    return uniqueCurrencies.sort();
  };

  const validCurrencies = getUniqueCurrencyFields(currencyOptions);

  const handleAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = Number(value);

    // Allow empty input or numeric values between 0 and 100 (temporary input)
    if (value === '' || (numericValue >= 0 && numericValue <= 100)) {
      setAge(value === '' ? '' : numericValue);
    }
  };

  return (
    <>
      <div className="form-row">
        <FaInfoCircle
          data-tooltip-id="infoTooltipGeneral"
          className="info-icon"
          id="infoTooltipGeneral"
        />
        <Tooltip
          id="infoTooltipGeneral"
          place="top-start"
          className="general-info-tooltip"
        >
          <p>
            We use your age and country to access relevant local statistical
            data, enabling us to estimate and benchmark your financial situation
            with greater accuracy tailored to your current circumstances.
          </p>
        </Tooltip>
      </div>
      <div className="form-row">
        <div className="form-group" style={{ minWidth: '20%' }}>
          <label htmlFor="age">Age:</label>
          <input
            id="age"
            type="number"
            className="form-control"
            value={age}
            onChange={handleAgeChange}
            required={true}
            placeholder="Enter your age (18-100)"
          />
          {age !== '' && (age < 18 || age > 100) && (
            <small className="text-danger">
              Age must be between 18 and 100.
            </small>
          )}
        </div>
        <div className="form-group" style={{ minWidth: '45%' }}>
          <label htmlFor="country">Country:</label>
          <SelectDropdownField
            options={
              incomeType === 'gross'
                ? grossValidCountries
                : sortedvalidCountries
            }
            value={country}
            onChange={setCountry}
            required={true}
            placeholder="Select Country"
            id="country-select"
          />
        </div>
        <div className="form-group" style={{ minWidth: '20%' }}>
          <label htmlFor="currency">Currency:</label>
          <SelectDropdownField
            options={validCurrencies}
            value={currency}
            onChange={setCurrency}
            required={true}
            placeholder="Select Currency"
            id="currency-select"
          />
        </div>
      </div>

      {/* <div className="form-row">
        <div className="info-container">
          <FaInfoCircle className="info-icon" />
          <div className="info-text">
            We use your age and country to access relevant local statistical
            data, enabling us to estimate and benchmark your financial situation
            with greater accuracy tailored to your current circumstances.
          </div>
        </div>
      </div> */}
    </>
  );
};

// Export the component
export default GeneralFormRow;
