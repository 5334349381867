import React, { useState, useEffect, useContext } from 'react';
import './IncomeFormRow.css'; // Import the CSS file for custom styles
import { FaInfoCircle } from 'react-icons/fa'; // Make sure to install react-icons or use another icon library
import SelectDropdownField from './../../FormFields/FormDropdownField';
import { Tooltip } from 'react-tooltip';
import { AppContext } from '../../../../AppContext';

interface IncomeFormRowProps {
  incomeType: string;
  setIncomeType: React.Dispatch<React.SetStateAction<string>>;
  totalIncomeYearlyGross: number | null;
  setTotalIncomeYearlyGross: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  totalIncomeYearlyNet: number | null;
  setTotalIncomeYearlyNet: React.Dispatch<React.SetStateAction<number | null>>;
  currency: string;
  currencyName: string;
  currencySymbol: string;
  grossValidCountries: string[];
}

const IncomeFormRow: React.FC<IncomeFormRowProps> = ({
  incomeType,
  setIncomeType,
  totalIncomeYearlyGross,
  setTotalIncomeYearlyGross,
  totalIncomeYearlyNet,
  setTotalIncomeYearlyNet,
  currency,
  currencyName,
  currencySymbol,
  grossValidCountries,
}) => {
  const appContext = useContext(AppContext);
  if (!appContext) {
    throw new Error('Header must be used within an AppProvider');
  }
  const {
    isMobileMenuBarOpen,
    setIsMobileMenuBarOpen,
    isMobile1100,
    setIsMobile1100,
    isMobile625,
    setIsMobile625,
  } = appContext;
  const [inputYearlyValue, setInputYearlyValue] = useState<string>('');
  const [inputMonthlyValue, setInputMonthlyValue] = useState<string>('');
  const [lastUpdated, setLastUpdated] = useState<'monthly' | 'yearly' | ''>('');

  // Helper function to format numbers with commas
  const formatNumberWithCommas = (value: string) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleIncomeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: 'gross' | 'net',
    frequency: 'monthly' | 'yearly'
  ) => {
    let value = e.target.value.replace(/,/g, ''); // Remove commas

    // Remove leading zero if it's the only character
    if (value.length > 1 && value.startsWith('0')) {
      value = value.slice(1);
    }

    const isValidInput = value === '' || /^\d+(\.\d{0,2})?$/.test(value);

    if (isValidInput) {
      setLastUpdated(frequency);

      // Parse the value to a number if it's not empty
      const numericValue = value === '' ? null : Number(value);

      if (frequency === 'yearly') {
        // Update yearly income
        type === 'gross'
          ? setTotalIncomeYearlyGross(numericValue)
          : setTotalIncomeYearlyNet(numericValue);

        setInputYearlyValue(formatNumberWithCommas(value));

        if (value !== '') {
          // Update monthly field as well
          setInputMonthlyValue(
            formatNumberWithCommas((Number(value) / 12).toFixed(2))
          );
        } else {
          // Clear monthly value if yearly is cleared
          setInputMonthlyValue('');
        }
      } else {
        // Update monthly income
        setInputMonthlyValue(formatNumberWithCommas(value));

        if (value !== '') {
          const yearlyIncome = (Number(value) * 12).toFixed(2);
          type === 'gross'
            ? setTotalIncomeYearlyGross(Number(yearlyIncome))
            : setTotalIncomeYearlyNet(Number(yearlyIncome));

          setInputYearlyValue(formatNumberWithCommas(yearlyIncome));
        } else {
          setInputYearlyValue(''); // Clear yearly value if monthly is cleared
        }
      }
    }
  };

  // Handle income type change
  const handleIncomeTypeChange = (value: string) => {
    if (value === 'gross' || value === 'net') {
      // Type narrowing to assure the compiler
      setIncomeType(value);

      setInputYearlyValue('');
      setInputMonthlyValue('');
      setTotalIncomeYearlyGross(null);
      setTotalIncomeYearlyNet(null);
    }
  };

  const incomeTypeOptions = ['gross', 'net'];

  const capitalizeFirstLetter = (str: string): string => {
    return str.replace(/^[a-z]/, (match: string) => match.toUpperCase());
  };
  return (
    <>
      <div className="income-form-row">
        <div className="title-box">
          <h5>
            {capitalizeFirstLetter(incomeType.toString())} Income (
            {currencySymbol})
          </h5>
        </div>
      </div>

      <div className="income-form-row">
        <div className="form-group income-type-title">
          <div className="form-group-income-type-info-container">
            <FaInfoCircle
              data-tooltip-id="infoTooltipGrossNetIncome"
              className="info-icon"
            />
            <Tooltip
              id="infoTooltipGrossNetIncome"
              place="top-start"
              className="income-type-info-tooltip"
            >
              {incomeType === 'gross' ? (
                <p>
                  Gross income refers to your total earnings before any taxes.
                  <br />
                  Based on this number we will automatically estimate your net
                  salary <br />
                  using the applicable tax brackets for your country. <br />
                  The list of Countries is more limited if you pick gross income
                  type. <br />
                  You can switch to net income type if you don't see your
                  country. <br />
                </p>
              ) : (
                <p>
                  Net income represents your earnings after all taxes have been
                  applied.
                </p>
              )}
            </Tooltip>
          </div>

          <label htmlFor="incomeType">Select Income Type:</label>
        </div>
      </div>

      <div className="income-form-row">
        <div className="form-group income-type">
          <SelectDropdownField
            options={incomeTypeOptions}
            value={incomeType}
            onChange={handleIncomeTypeChange}
            required={true}
            placeholder="Select Income Type"
            id="incomeType"
          />
        </div>
      </div>
      {incomeType === 'gross' ? (
        <div className="income-form-row">
          <div className="form-group income">
            <label htmlFor="monthlyIncomeGross">
              Monthly Income ({capitalizeFirstLetter(incomeType.toString())}) in{' '}
              {currency}:
            </label>
            <input
              id="monthlyIncomeGross"
              type="text"
              className="form-control"
              value={inputMonthlyValue === '' ? '' : inputMonthlyValue}
              onChange={(e) => handleIncomeChange(e, 'gross', 'monthly')}
              placeholder={`Enter your monthly ${incomeType} income (${currencySymbol})`}
            />
            {inputMonthlyValue !== '' &&
              Number(inputMonthlyValue.replace(/,/g, '')) <= 0 && (
                <small className="text-danger">
                  Income must be a positive number.
                </small>
              )}
          </div>
          <div className="form-group or-separator">
            <span>OR</span>
          </div>
          <div className="form-group income">
            <label htmlFor="yearlyIncomeGross">
              Yearly Income ({capitalizeFirstLetter(incomeType.toString())}) in{' '}
              {currency}:
            </label>
            <input
              id="yearlyIncomeGross"
              type="text"
              className="form-control"
              value={inputYearlyValue === '' ? '' : inputYearlyValue}
              onChange={(e) => handleIncomeChange(e, 'gross', 'yearly')}
              placeholder={`Enter your yearly ${incomeType} income (${currencySymbol})`}
            />
            {inputYearlyValue !== '' &&
              Number(inputYearlyValue.replace(/,/g, '')) <= 0 && (
                <small className="text-danger">
                  Income must be a positive number.
                </small>
              )}
          </div>
        </div>
      ) : null}
      {incomeType === 'net' ? (
        <div className="income-form-row">
          <div className="col-md-12 d-flex align-items-center justify-content-between">
            <div className="form-group income">
              <label htmlFor="monthlyIncomeNet">
                Monthly Income ({capitalizeFirstLetter(incomeType.toString())})
                in {currency}:
              </label>
              <input
                id="monthlyIncomeNet"
                type="text"
                className="form-control"
                value={inputMonthlyValue === '' ? '' : inputMonthlyValue}
                onChange={(e) => handleIncomeChange(e, 'net', 'monthly')}
                placeholder={`Enter your monthly ${incomeType} income (${currencySymbol})`}
              />
              {inputMonthlyValue !== '' &&
                Number(inputMonthlyValue.replace(/,/g, '')) <= 0 && (
                  <small className="text-danger">
                    Income must be a positive number.
                  </small>
                )}
            </div>
            <div className="form-group or-separator">
              <span>OR</span>
            </div>
            <div className="form-group income">
              <label htmlFor="yearlyIncomeNet">
                Yearly Income ({capitalizeFirstLetter(incomeType.toString())})
                in {currency}:
              </label>
              <input
                id="yearlyIncomeNet"
                type="text"
                className="form-control"
                value={inputYearlyValue === '' ? '' : inputYearlyValue}
                onChange={(e) => handleIncomeChange(e, 'net', 'yearly')}
                placeholder={`Enter your yearly ${incomeType} income (${currencySymbol})`}
              />
              {inputYearlyValue !== '' &&
                Number(inputYearlyValue.replace(/,/g, '')) <= 0 && (
                  <small className="text-danger">
                    Income must be a positive number.
                  </small>
                )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default IncomeFormRow;
