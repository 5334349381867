import React from 'react';
import './ButtonDark.css';

interface ButtonProps {
    text: string; // The text to display on the button
    handleButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void; // Optional handle button click function
    isLoading?: boolean; // Optional loading state
    className?: string; // Optional additional class names
    disabled?: boolean; // Optional disabled state
    style?: React.CSSProperties; // Optional inline styles
}

// Improved Button component
const Button: React.FC<ButtonProps> = ({
    text = "",
    handleButtonClick,
    isLoading = false,
    className = "",
    disabled = false,
    style = {},
}) => {


    return (
        <button
            onClick={handleButtonClick}
            className={`button-dark ${className}`} // Combine default and custom class names
            disabled={disabled || isLoading} // Disable if loading or explicitly disabled
            style={style} // Apply any inline styles
            aria-busy={isLoading} // Indicate loading state for assistive technologies
        >
            {isLoading ? (
                <span>Loading...</span> // Show loading text or spinner
            ) : (
                text // Show button text when not loading
            )}
        </button>
    );
};

export default Button;
