import React, { useState, useEffect, useContext } from 'react';
import './Fx.css';
import { FaCog, FaDollarSign, FaEuroSign } from 'react-icons/fa';
import { sendBackendRequest } from './../../../../Services/BackendRequestClient/BackendRequestClient';
import MobileSideBar from './../../../../components/MobileSideBar/MobileSideBar';
import { useAuth } from '../../../Login/AuthContext';
import { AppContext } from '../../../../AppContext';
import Toast from './../../../../components/ToastAlert/Toast';

interface FxCalcInputData {
  val: number;
  from_cy: string;
  to_cy: string;
}

interface FxCalcResponse {
  from_cy: string;
  to_cy: string;
  input: number;
  output: number;
}

const FxCalculator: React.FC = () => {
  // Context
  const { isAuthenticated, username, logout } = useAuth();
  const appContext = useContext(AppContext);
  if (!appContext) {
    throw new Error('Header must be used within an AppProvider');
  }
  const {
    isMobileMenuBarOpen,
    setIsMobileMenuBarOpen,
    isMobile1100,
    setIsMobile1100,
    isMobile625,
    setIsMobile625,
  } = appContext;

  useEffect(() => {
    document.title = 'Currency Calculator - Convert Currencies Instantly';
    document
      .querySelector("meta[name='description']")
      ?.setAttribute(
        'content',
        'Easily calculate currency conversions with our user-friendly FX Calculator. Input your amount, select the currencies, and get instant conversion results. Perfect for travelers, businesses, and anyone needing quick currency exchange information.'
      );
  }, []);

  const [formData, setFormData] = useState<FxCalcInputData>({
    val: 1,
    from_cy: 'USD',
    to_cy: 'EUR',
  });
  const [resultsData, setResultsData] = useState<FxCalcInputData>({
    val: 1,
    from_cy: 'USD',
    to_cy: 'EUR',
  });
  const [result, setResult] = useState<FxCalcResponse | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [fxLastUpdated, setFxLastUpdated] = useState(new Date());

  const getDateStr = () => {
    const now = new Date();
    const year = now.getFullYear();
    const day = String(now.getDate()).padStart(2, '0');

    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const month = monthNames[now.getMonth()]; // Get month name by index

    return `${month} ${day}, ${year}`;
  };

  useEffect(() => {
    const getYesterdayAt3AM = () => {
      const now = new Date();
      // Set the date to yesterday
      const yesterday = new Date(now);
      yesterday.setDate(now.getDate() - 1);
      // Set the time to 3 AM
      yesterday.setHours(3, 0, 0, 0); // hours, minutes, seconds, milliseconds
      return yesterday;
    };

    // Set the calculated date to the state
    setFxLastUpdated(getYesterdayAt3AM());
  }, []); // Run only once on mount

  // List of hardcoded currencies
  const currencyOptions = [
    'USD',
    'AED',
    'AFN',
    'ALL',
    'AMD',
    'ANG',
    'AOA',
    'ARS',
    'AUD',
    'AWG',
    'AZN',
    'BAM',
    'BBD',
    'BDT',
    'BGN',
    'BHD',
    'BIF',
    'BMD',
    'BND',
    'BOB',
    'BRL',
    'BSD',
    'BTN',
    'BWP',
    'BYN',
    'BZD',
    'CAD',
    'CDF',
    'CHF',
    'CLP',
    'CNY',
    'COP',
    'CRC',
    'CUP',
    'CVE',
    'CZK',
    'DJF',
    'DKK',
    'DOP',
    'DZD',
    'EGP',
    'ERN',
    'ETB',
    'EUR',
    'FJD',
    'FKP',
    'FOK',
    'GBP',
    'GEL',
    'GGP',
    'GHS',
    'GIP',
    'GMD',
    'GNF',
    'GTQ',
    'GYD',
    'HKD',
    'HNL',
    'HRK',
    'HTG',
    'HUF',
    'IDR',
    'ILS',
    'IMP',
    'INR',
    'IQD',
    'IRR',
    'ISK',
    'JEP',
    'JMD',
    'JOD',
    'JPY',
    'KES',
    'KGS',
    'KHR',
    'KID',
    'KMF',
    'KRW',
    'KWD',
    'KYD',
    'KZT',
    'LAK',
    'LBP',
    'LKR',
    'LRD',
    'LSL',
    'LYD',
    'MAD',
    'MDL',
    'MGA',
    'MKD',
    'MMK',
    'MNT',
    'MOP',
    'MRU',
    'MUR',
    'MVR',
    'MWK',
    'MXN',
    'MYR',
    'MZN',
    'NAD',
    'NGN',
    'NIO',
    'NOK',
    'NPR',
    'NZD',
    'OMR',
    'PAB',
    'PEN',
    'PGK',
    'PHP',
    'PKR',
    'PLN',
    'PYG',
    'QAR',
    'RON',
    'RSD',
    'RUB',
    'RWF',
    'SAR',
    'SBD',
    'SCR',
    'SDG',
    'SEK',
    'SGD',
    'SHP',
    'SLE',
    'SLL',
    'SOS',
    'SRD',
    'SSP',
    'STN',
    'SYP',
    'SZL',
    'THB',
    'TJS',
    'TMT',
    'TND',
    'TOP',
    'TRY',
    'TTD',
    'TVD',
    'TWD',
    'TZS',
    'UAH',
    'UGX',
    'UYU',
    'UZS',
    'VES',
    'VND',
    'VUV',
    'WST',
    'XAF',
    'XCD',
    'XDR',
    'XOF',
    'XPF',
    'YER',
    'ZAR',
    'ZMW',
    'ZWL',
  ];

  const currencyOptionsSorted = Array.from(new Set(currencyOptions)).sort();

  // Handle form input changes
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // setError('An error occurred while calculating the FX conversion.'); // TEST ERROR IN SUBMIT BUTTON
    try {
      const responseDataResults = await sendBackendRequest({
        rel_endpoint: '/calculator/fx',
        method: 'POST',
        body: formData,
        useCache: true,
      });

      setResult(responseDataResults);
      setResultsData(formData);
      setError(null);
    } catch (err) {
      setError('An error occurred while calculating the FX conversion.');
      setResult(null);
    }
  };

  // Swap currencies function
  const handleSwapCurrencies = () => {
    setFormData({
      ...formData,
      from_cy: formData.to_cy,
      to_cy: formData.from_cy,
    });
  };

  const formatCurrency = (value: number): string => {
    return value.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const [showToast, setShowToast] = useState(false);

  const handleOutputValCopy = () => {
    navigator.clipboard
      .writeText(result ? Number(result.output).toString() : '')
      .then(() => {
        setShowToast(true);

        const timeoutId = setTimeout(() => setShowToast(false), 3000);

        return () => clearTimeout(timeoutId);
      })
      .catch((err) => {
        console.error('Error copying text: ', err);
      });
  };

  return (
    <>
      <MobileSideBar />
      <section
        className={`calculator-fx-section ${isMobile1100 ? 'mobile' : ''}`}
      >
        <div
          className={`calculator-fx-section-container ${
            isMobile1100 ? 'mobile' : ''
          }`}
        >
          <div className="benchmark-container-title">
            <h2 className="benchmark-container-title-icon-left">💱</h2>
            <h2>Currency Converter</h2>
          </div>

          <p className="benchmark-container-description">
            Our Currency Converter is a powerful and user-friendly online tool
            designed to provide real-time exchange rates and enable seamless
            currency conversions. Whether you're planning to travel, shopping
            internationally, or managing foreign investments, this tool
            simplifies the process of converting currencies at a glance.
          </p>

          <form onSubmit={handleSubmit}>
            <div className="form-section">
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="val">Amount:</label>
                  <input
                    className="form-input"
                    type="number"
                    name="val"
                    value={formData.val}
                    onChange={handleInputChange}
                    required={true}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="from_cy">From Currency:</label>{' '}
                  <select
                    className="form-select"
                    name="from_cy"
                    value={formData.from_cy}
                    onChange={handleInputChange}
                    required
                  >
                    {currencyOptionsSorted.map((currency) => (
                      <option key={currency} value={currency}>
                        {currency}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group-swap-button">
                  <button
                    className="swap-button"
                    type="button"
                    onClick={handleSwapCurrencies}
                  >
                    ↔️
                  </button>
                </div>
                <div className="form-group">
                  <label htmlFor="to_cy">To Currency:</label>
                  <select
                    className="form-select"
                    name="to_cy"
                    value={formData.to_cy}
                    onChange={handleInputChange}
                    required
                  >
                    {currencyOptionsSorted.map((currency) => (
                      <option key={currency} value={currency}>
                        {currency}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group-submit-button">
                  <button type="submit" className="submit-button">
                    Calculate
                  </button>
                </div>
              </div>

              {error && <p className="error-message">{error}</p>}
            </div>
          </form>
        </div>

        {result && (
          <>
            <div
              className={`calculator-fx-results-container ${
                isMobile1100 ? 'mobile' : ''
              }`}
            >
              <div className="result-row">
                <span className="result-row-result-left-input-equals">
                  <span className="result-row-result-text-content">
                    {formatCurrency(Number(resultsData.val))}{' '}
                    {resultsData.from_cy}{' '}
                  </span>
                </span>
                <span className="equals-sign">=</span>
                <span
                  className="result-row-result-center-input-output"
                  onClick={handleOutputValCopy}
                >
                  {showToast && (
                    <Toast
                      message="Result copied to clipboard!"
                      className="light"
                    />
                  )}
                  <span className="result-row-result-text-content">
                    {formatCurrency(Number(result.output))}
                  </span>
                </span>
                <span>{resultsData.to_cy} </span>
              </div>
              <div className="result-row-small">
                <span className="date-info">
                  As of {getDateStr()} —{' '}
                  {formatCurrency(Number(resultsData.val))}{' '}
                  {resultsData.from_cy} converts to{' '}
                  {formatCurrency(result.output)} {resultsData.to_cy} based on
                  the latest exchange rate.
                </span>
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default FxCalculator;
