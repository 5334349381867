import './IncomeLivabilityCalculator.css';
import { useAuth } from '../../../Login/AuthContext';
import { AppContext } from '../../../../AppContext';
import React, { useState, useRef, useEffect, useContext } from 'react';
import GeneralFormRow, {
  getCurrencySymbol,
  getCurrencyName,
} from './../../../../components/Forms/FormRows/GeneralFormRow/GeneralFormRow';
import IncomeFormRow from './../../../../components/Forms/FormRows/IncomeFormRow/IncomeFormRow';
import SavingsInvestmentsFormRow from './../../../../components/Forms/FormRows/SavingsInvestmentsFormRow/SavingsInvestmentsFormRow';
import ClipLoader from 'react-spinners/ClipLoader';
import IncomeLivabilityResults from './../../../../components/ScoreCards/IncomeLivabilityResults/IncomeLivabilityResults';
import { FaCog, FaDollarSign, FaEuroSign } from 'react-icons/fa';
import LoadingOverlayScreen from '../../../../components/Loading/LoadingOverlayScreen';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import { sendBackendRequest } from './../../../../Services/BackendRequestClient/BackendRequestClient';
import MobileSideBar from './../../../../components/MobileSideBar/MobileSideBar';

interface IncomeLivabilityInputData {
  age: number | '';
  country: string;
  currency: string;
  total_income_yearly_gross: number | null;
  total_income_yearly_net: number | null;
}

type CountryMap = {
  [key: string]: string; // key is the country code (e.g., "AU"), value is the country name (e.g., "Australia")
};

const Benchmark: React.FC = () => {
  // Context
  const { isAuthenticated, username, logout } = useAuth();
  const appContext = useContext(AppContext);
  if (!appContext) {
    throw new Error('Header must be used within an AppProvider');
  }
  const {
    isMobileMenuBarOpen,
    setIsMobileMenuBarOpen,
    isMobile1100,
    setIsMobile1100,
    isMobile625,
    setIsMobile625,
  } = appContext;

  // Status States
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [verbose, setVerbose] = useState(false);
  // IncomeLivabilityInputData States
  const [age, setAge] = useState<number | ''>('');
  const [country, setCountry] = useState<string | ''>('United States');
  const [currency, setCurrency] = useState('USD');
  const [totalIncomeYearlyGross, setTotalIncomeYearlyGross] = useState<
    number | null
  >(null);
  const [totalIncomeYearlyNet, setTotalIncomeYearlyNet] = useState<
    number | null
  >(null);
  // Extra Niceties States like currency lookup text
  const [currencyName, setCurrencyName] = useState<string>(
    getCurrencyName(currency)
  );
  const [currencySymbol, setCurrencySymbol] = useState<string>(
    getCurrencySymbol(currency)
  );
  const [grossValidCountries, setGrossValidCountries] = useState<CountryMap>(
    {}
  );
  const [grossValidCountriesNames, setGrossValidCountriesNames] = useState<
    string[]
  >([]);
  const [incomeType, setIncomeType] = useState<string>('gross');
  // Ref State
  const resultRef = useRef<HTMLDivElement | null>(null);
  // Input/Output Data States
  const [inputReportData, setInputReportData] =
    useState<IncomeLivabilityInputData>({
      age: age,
      country: country,
      currency: currency,
      total_income_yearly_gross: null,
      total_income_yearly_net: null,
    });
  const [dataResults, setDataResults] = useState(null);

  useEffect(() => {
    // Define an async function to fetch data
    const fetchData = async () => {
      try {
        setLoading(true);

        const result = await sendBackendRequest({
          rel_endpoint: '/knowledge_base/gross_to_net_auto_countries',
          method: 'GET',
          useCache: true,
        });

        setGrossValidCountries(result);
        setGrossValidCountriesNames(Object.values(result));
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError('An unexpected error occurred');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this will only run once on mount

  // useEffect to update currencyName and currencySymbol when currency changes
  useEffect(() => {
    setCurrencyName(getCurrencyName(currency));
    setCurrencySymbol(getCurrencySymbol(currency));
  }, [currency]); // Only run this effect when 'currency' changes

  // Scroll to results when loading is false and dataResults is available
  useEffect(() => {
    if (!loading && dataResults) {
      scrollToResult();
    }
  }, [loading, dataResults]); // This effect runs when loading or dataResults changes

  const scrollToResult = () => {
    if (resultRef.current) {
      resultRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate the income fields
    if (!isFormValid()) {
      alert(
        'Error: Total Income Yearly Gross and Total Income Yearly Net must be greater than 0.'
      );
      return; // Prevent form submission
    }

    const formData = {
      age: Math.round(Number(age)),
      currency: currency,
      verbose: verbose,
      country: country.toString(),
      total_income_yearly_gross: Number(totalIncomeYearlyGross),
      total_income_yearly_net: Number(totalIncomeYearlyNet),
    };

    setInputReportData(formData);
    setError('');
    setLoading(true);

    try {
      const responseDataResults = await sendBackendRequest({
        rel_endpoint: '/calculator/income_livability',
        method: 'POST',
        body: formData,
        useCache: true,
      });

      setDataResults(responseDataResults);

      // Scroll to results after data is set
      // scrollToResult();
    } catch (err) {
      console.error(err);
      setError(`An unknown error happened when generating the report.`);
    } finally {
      setLoading(false);
    }
  };

  const isFormValid = () => {
    return !(
      (Number(totalIncomeYearlyGross) <= 0 ||
        totalIncomeYearlyGross === null) &&
      (Number(totalIncomeYearlyNet) <= 0 || totalIncomeYearlyNet === null)
    );
  };

  return (
    <>
      <MobileSideBar />
      <section className="main-inc-liv-calc-section">
        {loading && <LoadingOverlayScreen loading={loading} />}
        <div
          className={`main-inc-liv-calc-section-container ${
            isMobile1100 ? 'mobile' : ''
          }`}
        >
          <div
            className={`benchmark-container-title ${
              isMobile1100 ? 'mobile' : ''
            }`}
          >
            <h2 className="benchmark-container-title-icon-left">🏠</h2>
            <h2>Income Livability Calculator</h2>
          </div>
          <p className="benchmark-container-description">
            {isMobile1100
              ? 'The Income Livability Calculator assesses how well an individual’s income covers average living costs based on age and country. It evaluates expenses, housing, and purchasing power against global standards, providing a quick financial health check. '
              : "The Income Livability Calculator is a valuable tool that assesses how well an adult individual's income meets average living costs based on age and country. It analyzes key factors such as living expenses, housing costs, and purchasing power, comparing them to global standards in developed countries. This calculator offers a quick health check on an adult individual's personal finances."}
          </p>
          <form onSubmit={handleSubmit} className="benchmark-form">
            <div className="form-section">
              <GeneralFormRow
                age={age}
                setAge={setAge}
                country={country}
                setCountry={setCountry}
                currency={currency}
                setCurrency={setCurrency}
                grossValidCountries={grossValidCountriesNames}
                incomeType={incomeType}
              />
            </div>
            <div className="form-section">
              <IncomeFormRow
                incomeType={incomeType}
                setIncomeType={setIncomeType}
                totalIncomeYearlyGross={totalIncomeYearlyGross}
                setTotalIncomeYearlyGross={setTotalIncomeYearlyGross}
                totalIncomeYearlyNet={totalIncomeYearlyNet}
                setTotalIncomeYearlyNet={setTotalIncomeYearlyNet}
                currency={currency}
                currencyName={currencyName}
                currencySymbol={currencySymbol}
                grossValidCountries={grossValidCountriesNames}
              />
            </div>
            <br></br>
            <button
              className="submit-button"
              type="submit"
              disabled={!isFormValid()}
            >
              Generate Report
            </button>
          </form>
        </div>
        {error ? <div className="error-message">Error: {error}</div> : null}
        {dataResults ? (
          <div ref={resultRef}>
            <IncomeLivabilityResults
              results={dataResults}
              currencySymbol={currencySymbol}
              country={country}
              age={age}
            />
          </div>
        ) : null}
      </section>
    </>
  );
};

export default Benchmark;
