// BlogList.tsx
import './BlogList.css';
import React from 'react';
import { blogPostsData } from './../BlogPosts/BlogPostsData';
import { Link } from 'react-router-dom';
import MobileSideBar from './../../../components/MobileSideBar/MobileSideBar';
import Button from './../../../components/Button/Button'


interface BlogListProps {
  month?: string;
}


const BlogList: React.FC<BlogListProps> = ({ month }) => {
  const headerStyle = {
    backgroundImage: `url('/images/banner_image_1900x600.png')`,
  };
  const filteredPosts = blogPostsData.filter(
    (post) => !month || post.month === month
  );
  const sortedPosts = filteredPosts.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );

  console.log(month)
  


  return (
    <div className="blog-list-container">
      <MobileSideBar />
      <div className="blog-list-header" style={headerStyle}>
        <h1 className="blog-list-title">
          {month ? formatMonth(month) : 'Discover Our Latest Blog Posts'}
        </h1>
        <p className="blog-list-description">
          {month
            ? `Posts from ${formatMonth(month)}.`
            : 'Stay updated with industry trends, tips, benchmarks, and insights from our team of personal finance experts and researchers.'}
        </p>
        {/* Add navigation link to view all posts when a month is selected */}
        {month && (
          <div className="back-to-all-posts">
            <Link to="/blog" className="back-link">
              &larr; View All Posts
            </Link>
          </div>
        )}
      </div>

      <div className="blog-list">
        {sortedPosts.length > 0 ? (
          sortedPosts.map((post) => (
            <Link
              key={post.postName}
              to={`/blog/${post.month}/${post.postName}`}
              className="blog-card-link"
            >
              <div className="blog-card">
                <div className="blog-card-image">
                  <img src={post.image} alt={post.title} />
                </div>
                <div className="blog-card-content">
                  <h2 className="blog-card-title">{post.title}</h2>
                  <div className="blog-card-meta">
                    <p className="blog-card-date">
                      {new Date(post.date).toDateString()}
                    </p>
                  </div>
                  <p className="blog-card-excerpt">{post.excerpt}</p>
                  <Button text="Read More" />
                </div>
              </div>
            </Link>
          ))
        ) : (
          <p>No posts found for this month.</p>
        )}
      </div>
    </div>
  );
};

// Helper function to format month code to display format
function formatMonth(monthCode: string): string {
  const regex = /^([a-z]+)(\d{2})$/;
  const match = monthCode.match(regex);
  if (match) {
    const monthName = match[1];
    const yearShort = match[2];
    const monthCapitalized =
      monthName.charAt(0).toUpperCase() + monthName.slice(1);
    const yearFull = '20' + yearShort;
    return `${monthCapitalized} ${yearFull}`;
  } else {
    return monthCode;
  }
}

export default BlogList;
