import React from 'react';
import './Toast.css';

interface ToastProps {
  message: string;
  className?: string; // Optional className prop
}

const Toast: React.FC<ToastProps> = ({ message, className }) => {
  return (
    <div className={`toast-alert-toast-element ${className}`}>
      {message}
    </div>
  );
};

export default Toast;
