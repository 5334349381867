import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import './IncomeLivabilityBenchmark.css';
import LoadingOverlayScreen from './../../../../components/Loading/LoadingOverlayScreen';
import { FaInfoCircle, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import BarChart from '../../../../components/BarChart/BenchmarkBarChart';
import Button from '../../../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import DropdownField from './../../../../components/Forms/FormFields/FormDropdownField';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import MobileSideBar from './../../../../components/MobileSideBar/MobileSideBar';
// Context
import { useAuth } from '../../../Login/AuthContext';
import { AppContext } from '../../../../AppContext';
// CachedResults
import {
  JobTitle,
  defJobTitle,
  IncomeLivabilityBenchmarkData,
  incomeLivabilityBenchmarkData_2024_11_02,
} from './cache/benchmarkResults';
import ShareButton from './../../../../components/Button/ShareButton';

interface BenchmarkParams {
  [key: string]: string | undefined; // This allows for any key with string values or undefined
}

const IncomeLivabilityBenchmark: React.FC = () => {
  // Params
  const { urlJobTitle } = useParams<BenchmarkParams>();
  // Context
  const { isAuthenticated, username, logout } = useAuth();
  const appContext = useContext(AppContext);
  if (!appContext) {
    throw new Error('Header must be used within an AppProvider');
  }
  const {
    isMobileMenuBarOpen,
    setIsMobileMenuBarOpen,
    isMobile1100,
    setIsMobile1100,
    isMobile625,
    setIsMobile625,
  } = appContext;

  // States
  const [incomeLivabilityBenchmark, setIncomeLivabilityBenchmark] =
    useState<IncomeLivabilityBenchmarkData | null>(
      incomeLivabilityBenchmarkData_2024_11_02
    );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedJob, setSelectedJob] = useState<JobTitle>('Fast Food Worker');
  const navigate = useNavigate(); // Initialize the navigate function

  const [isTableVisible, setIsTableVisible] = useState<boolean>(false); // New state for table visibility

  const jobMapping: { [key: string]: JobTitle } = {
    'software-engineer': 'Software Engineer',
    'fast-food-worker': 'Fast Food Worker',
    // Add more job mappings as needed
  };

  function getJobKeyFromValue(
    jobMapping: { [key: string]: string },
    value: string
  ): string | undefined {
    // Invert the job mapping and look up the key
    const inverseJobMapping = Object.fromEntries(
      Object.entries(jobMapping).map(([key, val]) => [val, key])
    );

    return inverseJobMapping[value];
  }

  // Use effect to update selectedJob based on the URL parameter
  useEffect(() => {
    // Map job titles from the URL-friendly format to human-readable format

    // Update state if the jobTitle is found in the mapping
    if (urlJobTitle && jobMapping[urlJobTitle]) {
      setSelectedJob(jobMapping[urlJobTitle]);
    } else {
      navigate('/benchmarks/income-livability');
      setSelectedJob(defJobTitle);
    }
  }, [urlJobTitle]); // Runs when the jobTitle parameter in the URL changes

  const toggleTableVisibility = () => {
    setIsTableVisible(!isTableVisible);
  };

  const selectedJobBackend = selectedJob
    .toLowerCase()
    .replace(/ /g, '_') as keyof IncomeLivabilityBenchmarkData['results'];
  const getSortedSelectedDataDescending = () => {
    if (!incomeLivabilityBenchmark || !incomeLivabilityBenchmark.results) {
      return []; // Return an empty array if data is not available
    }

    const jobData = incomeLivabilityBenchmark.results[selectedJobBackend];

    if (!jobData || !Array.isArray(jobData)) {
      return []; // Return an empty array if jobData is undefined or not an array
    }

    return [...jobData].sort((a, b) => b.overall - a.overall);
  };

  const sortedSelectedDataDescending = getSortedSelectedDataDescending();

  const handleTryIncomeLivCalcButton = () => {
    navigate('/calculators/income-livability'); // Redirect to the desired route
  };
  const options = ['Fast Food Worker', 'Software Engineer'];
  const sortedOptions = options.sort();

  const handleClearCache = () => {
    localStorage.removeItem('incomeLivabilityBenchmark');
    // setIncomeLivabilityBenchmark(null)
    alert('Cache cleared!'); // Optional: Notify the user
  };

  const formatCurrency = (value: number): string => {
    return value.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  return (
    <>
      <MobileSideBar />
      <div className="benchmark-main">
        <div className="benchmark-section">
          {/* <br /><button onClick={handleClearCache} className="clear-cache-button">Clear Cached Data</button> */}
          {loading ? (
            <LoadingOverlayScreen loading={loading} size={100} />
          ) : error ? (
            <div>Error: {error}</div>
          ) : (
            <div
              className={`benchmark-section-container ${
                isMobile1100 ? 'mobile' : ''
              }`}
            >
              <div
                className={`benchmark-section-container-title ${
                  isMobile1100 ? 'mobile' : ''
                }`}
              >
                <h1> 🌎 Income Livability Benchmark</h1>
                <div className="benchmark-section-container-title-job-container">
                  <h2>Income Livability Index by Country and Job Title </h2>
                  <DropdownField
                    options={sortedOptions}
                    id={'jobSelectId'}
                    value={selectedJob}
                    onChange={(value) => setSelectedJob(value as JobTitle)}
                    // className="benchmark-section-container-title-job-container-dropdown"
                  />
                </div>
              </div>
              <div className="benchmark-section-container-section">
                <div className="benchmark-section-container-section-plot">
                  <BarChart
                    data={sortedSelectedDataDescending}
                    barThickness={9}
                    indexAxis="y"
                  />
                </div>
                <div className="benchmark-section-container-section-cta-button">
                  <Button
                    text={
                      isMobile625
                        ? '🔍 Test your score for free'
                        : '🔍 Find out your income livability score for free'
                    }
                    handleButtonClick={handleTryIncomeLivCalcButton}
                    className="small"
                    showArrow={false}
                  />
                </div>
                <div className="benchmark-section-container-section-description">
                  <div className="info-container">
                    <FaInfoCircle className="info-icon" />
                    <div className="info-text">
                      <strong>How to Interpret These Results:</strong> Higher
                      Scores indicate better income livability. This means that
                      adult individuals, on average, enjoy higher purchasing
                      power and greater financial freedom. A high score suggests
                      that living costs are more manageable, resulting in
                      enhanced financial security and flexibility. Lower Scores,
                      conversely, signify tighter financial margins. This often
                      leads to less surplus income after covering essential
                      living expenses.
                    </div>
                  </div>
                </div>
              </div>

              <div className="benchmark-section-container-bottom-buttons">
                <ShareButton
                  customUrl={`/benchmarks/income-livability/${getJobKeyFromValue(
                    jobMapping,
                    selectedJob.toString()
                  )}`}
                  className="benchmark-share-button"
                />
              </div>

              {/* Toggle Button for Table */}
              <Button
                text={isTableVisible ? '⬆️ Hide Data' : '⬇️ See Data'}
                handleButtonClick={toggleTableVisibility}
                // className="toggle-table-button"
              />
              {/* {isTableVisible ? (
                  <FaChevronUp className="toggle-table-button-arrow-icon" />
                ) : (
                  <FaChevronDown className="toggle-table-button-arrow-icon" />
                )}{' '}
                {isTableVisible ? ' Hide' : ' Show'} Table Data
              </button> */}

              {/* Collapsible Table */}
              {isTableVisible && (
                <div className="table-container">
                  Data for Job: {selectedJob}
                  (All amounts are in the local currency. Median salaries are
                  presented as gross values.)
                  <table
                    className={`benchmark-table ${
                      !isMobile625 ? '' : 'mobile'
                    }`}
                  >
                    <thead>
                      <tr>
                        <th>Country</th>
                        <th>Income Livability Score</th>
                        <th>Median Salary (gross/yr)</th>
                        <th>Median Salary (gross/mo)</th>
                        <th>Currency</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedSelectedDataDescending
                        ? sortedSelectedDataDescending.map((data, index) => (
                            <tr key={index}>
                              <td>
                                <FlagIcon
                                  code={data.country_code as FlagIconCode}
                                  width={3 * 5}
                                  height={2 * 5}
                                />{' '}
                                {data.country}
                              </td>
                              <td>{data.overall.toFixed(2)}</td>
                              <td>
                                {formatCurrency(data.median_salary_gross_yr)}
                              </td>
                              <td>
                                {formatCurrency(
                                  data.median_salary_gross_yr / 12
                                )}
                              </td>
                              <td>{data.currency.toString()}</td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default IncomeLivabilityBenchmark;
