import React from 'react';
import { Link } from 'react-router-dom';
import './InConstruction.css'; // Optional: Create a separate CSS file for styling
import MobileSideBar from '../../components/MobileSideBar/MobileSideBar';

const NotFound: React.FC = () => {
  return (
    <>
      <MobileSideBar />
      <section className="full-screen-main-section">
        <div className="not-found">
          <div className="under-construction">
            <h2>We're working on it!</h2>
            <p>This feature will be available soon. Please check back later.</p>
          </div>

          <Link to="/">Go Back to Home</Link>
          <br />
          <Link to="/calculators/income-livability">
            Try our Income Livability Calculator 🏠
          </Link>
          <br />
          <Link to="/benchmarks/income-livability">
            Gain Insights in our Global Benchmarks 🌎
          </Link>
        </div>
      </section>
    </>
  );
};

export default NotFound;
