// src/services/backendRequest.ts

export interface BackendRequestParams {
  rel_endpoint: string;
  method: 'GET' | 'POST';
  body?: { [key: string]: any }; // Allowing any key-value pairs
  useCache?: boolean;
}

const requestCache = new Map<string, any>();

// Function to generate the HMAC SHA-256 signature
export const generateSignature = async () => {
  const timestamp = Math.floor(Date.now());
  const timestampStrReturn = (timestamp + 1337).toString(); // Add extra trick, offset by 1337 milisecs , decrypter must know about offset
  const dataToSign = `eigil@pocketnumbers.com:${timestamp.toString()}:no:hackers:allowed`;

  // Use the Web Cryptography API for signature generation
  const encoder = new TextEncoder();
  const key = await crypto.subtle.importKey(
    'raw',
    encoder.encode(
      `${process.env.REACT_APP_BACKEND_REQUEST_CLIENT_CRYPT_SECRET_KEY}`
    ),
    { name: 'HMAC', hash: 'SHA-256' },
    false,
    ['sign']
  );

  const signatureBuffer = await crypto.subtle.sign(
    'HMAC',
    key,
    encoder.encode(dataToSign)
  );

  const signatureArray = new Uint8Array(signatureBuffer);
  const signature = Array.from(signatureArray)
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('');

  return { signature, timestampStrReturn };
};

// Function to send the request to the backend
export const sendBackendRequest = async ({
  rel_endpoint,
  method = 'GET', // Default to GET if not specified
  body = {}, // Default to an empty object if no body is provided
  useCache = false, // Default to true, enabling caching
}: BackendRequestParams) => {
  const cacheKey = JSON.stringify({ rel_endpoint, method, body });

  // Check if response is already cached
  if (useCache && requestCache.has(cacheKey)) {
    console.log('Returning cached response');
    return requestCache.get(cacheKey);
  }

  try {
    const { signature, timestampStrReturn } = await generateSignature();

    const timestamp = timestampStrReturn;

    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}${rel_endpoint}`,
      {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'X-Signature': signature,
          'X-Timestamp': timestamp,
        },
        body: ['POST'].includes(method) ? JSON.stringify(body) : null,
      }
    );

    if (!response.ok) {
      throw new Error('Failed to calculate');
    }

    const resultData = await response.json();


    // If caching is enabled, store the response in the cache
    if (useCache) {
      requestCache.set(cacheKey, resultData);
    }
    
    return resultData; // Return the result so it can be used where the function is called
  } catch (err) {
    throw new Error(err instanceof Error ? err.message : 'Unknown error');
  }
};
