// BlogPostData.tsx
import Nov24_FastFoodPaychecks from './Posts/Nov24_FastFoodPaychecks';
import Dec24_Test from './Posts/Dec24_Test';

export interface BlogPostData {
  title: string;
  month: string;
  postName: string;
  date: string; // ISO date string for sorting
  component: React.FC;
  excerpt: string; // Brief summary or teaser for SEO
  image: string; // High-quality and descriptive image for SEO 2
}

export const blogPostsData: BlogPostData[] = [
  {
    title: 'How Much Do Fast Food Workers Earn Globally in 2024?',
    month: 'november24',
    postName: 'fast-food-paychecks',
    date: '2024-11-24',
    component: Nov24_FastFoodPaychecks,
    excerpt: 'Discover global pay trends for fast-food workers in 2024, from the U.S. to Europe and Asia.',
    image: '/images/blogs/fast-food-paychecks-global-2024.png',
  },
  // {
  //   title: 'Christmas Gifts Around the World: A Tale of Purchasing Power',
  //   month: 'december24',
  //   postName: 'christmas-gifts-and-purchasing-power',
  //   date: '2024-12-11',
  //   component: Dec24_Test,
  //   excerpt: 'Explore how purchasing power shapes the value of gifts across different countries and cultures.',
  //   image: '/images/blogs/fast-food-paychecks-global-2024.png',
  // },
  
  
  // // Additional blog posts
  // {
  //   title: 'Beating the January Blues: Financial Goals for 2025',
  //   month: 'january25',
  //   postName: 'january-blues-financial-goals',
  //   date: '2025-01-15',
  //   component: Nov24_FastFoodPaychecks, // Replace with the correct component
  //   excerpt: 'Kickstart 2025 with actionable financial tips to beat the January blues and stay motivated.',
  //   image: '/images/blogs/fast-food-paychecks-global-2024.png',
  // },
];
