// App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home/Home';
import SignUp from './pages/Signup/Signup';
import Login from './pages/Login/Login';
import Header from './pages/Header/Header';
import Profile from './pages/Profile/Profile';
import InConstruction from './pages/InContruction/InConstruction';
import { AuthProvider } from './pages/Login/AuthContext';
import NotFound from './pages/404Page/404Page';
import Benchmark from './pages/BenchmarkOld/Benchmark';
import FxCalc from './pages/Features/Calculators/FxCalculator/Fx';
import IncomeLivabilityCalc from './pages/Features/Calculators/IncomeLivabilityCalculator/IncomeLivabilityCalculator';
import IncomeLivabilityBenchmark from './pages/Features/Benchmarks/IncomeLivabilityBenchmark/IncomeLivabilityBenchmark';
import { AppProvider } from './AppContext';
import Blog from './pages/Blog/Blog';

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppProvider>
          <div className="App">
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<Login />} />
              <Route path="/profile" element={<Profile />} />

              <Route path="/calculators/fx" element={<FxCalc />} />
              <Route
                path="/calculators/income-livability"
                element={<IncomeLivabilityCalc />}
              ></Route>
              <Route
                path="/benchmarks/income-livability/:urlJobTitle?"
                element={<IncomeLivabilityBenchmark />}
              />
              <Route
                path="/feature/smart-personal-finances"
                element={<InConstruction />}
              />

              <Route path="/blog/:month?" element={<Blog />} />
              <Route path="/blog/:month/:postName" element={<Blog />} />
              {/* Catch all unmatched routes */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </AppProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
