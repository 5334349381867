import React from 'react';
import './BlogSideMenu.css';
import { blogPostsData } from './../BlogPosts/BlogPostsData';

const BlogSideMenu: React.FC = () => {
  const uniqueMonthsSet = new Set(blogPostsData.map((post) => post.month));
  const uniqueMonths = Array.from(uniqueMonthsSet);

  const monthDates = uniqueMonths
    .map((monthCode) => {
      const postsInMonth = blogPostsData.filter((post) => post.month === monthCode);
      if (postsInMonth.length === 0) {
        console.warn(`No posts found for monthCode: ${monthCode}`);
        return null;
      }
      const date = new Date(postsInMonth[0].date);
      return {
        monthCode,
        date: new Date(date.getFullYear(), date.getMonth(), 1),
        count: postsInMonth.length, // Add the count here
      };
    })
    .filter(
      (entry): entry is { monthCode: string; date: Date; count: number } => entry !== null
    );

  function formatMonth(monthCode: string): string {
    // Regex to capture the month name and year digits
    const regex = /^([a-z]+)(\d{2})$/;
    const match = monthCode.match(regex);
    if (match) {
      const monthName = match[1]; // e.g., 'november'
      const yearShort = match[2]; // e.g., '24'
      // Capitalize the first letter of the month
      const monthCapitalized =
        monthName.charAt(0).toUpperCase() + monthName.slice(1);
      // Convert '24' to '2024'
      const yearFull = '20' + yearShort;
      return `${monthCapitalized} ${yearFull}`;
    } else {
      // Return the original code if it doesn't match the expected format
      return monthCode;
    }
  }

  const archives = monthDates.map(({ monthCode, count }) => ({
    code: monthCode,
    display: formatMonth(monthCode),
    count, 
  }));



  monthDates.sort((a, b) =>  a.date.getTime() + b.date.getTime());


  return (
    <nav className="blog-side-menu">
      <a href="/blog" className="menu-title">
        All Blog Posts
      </a>
      {archives.map((archive, index) => (
        <a key={index} href={`/blog/${archive.code}`} className="archive-link">
          {archive.display} <span className="archive-count">({archive.count})</span>
        </a>
      ))}
    </nav>
  );
};

export default BlogSideMenu;
