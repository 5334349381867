import React from 'react';
import './Button.css';
import ButtonArrow from "./Arrow"

interface ButtonProps {
  text: string; // The text to display on the button
  handleButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void; // Optional handle button click function
  isLoading?: boolean; // Optional loading state
  className?: string; // Optional additional class names
  disabled?: boolean; // Optional disabled state
  style?: React.CSSProperties; // Optional inline styles
  showArrow?: boolean;
}

// Improved Button component
const Button: React.FC<ButtonProps> = ({
  text = '',
  handleButtonClick,
  isLoading = false,
  className = '',
  disabled = false,
  style = {},
  showArrow = false,
}) => {
  return (
    <button
      onClick={handleButtonClick}
      className={`button ${className}`}
      disabled={disabled || isLoading}
      style={style}
      aria-busy={isLoading}
    >
      {isLoading ? (
        <span>Loading...</span>
      ) : (
        <div className="FlexContainerstyled__FlexContainer-sc-1ol5ce3-0 dyvXqV">
          {text}
          {showArrow && (
           <ButtonArrow/>
          )}
        </div>
      )}
    </button>
  );
};

export default Button;
