import React from 'react';
import { useAuth } from '../Login/AuthContext'; // Adjust the import path as necessary
import './Profile.css'; // Import the CSS styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faEnvelope,
  faMoneyBillWave,
  faPiggyBank,
  faChartLine,
  faBirthdayCake,
  faCog,
  faBell,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import Button from './../../components/Button/Button';

const Profile: React.FC = () => {
  const { username, email, userId, isAuthenticated, userCreatedAt, logout } =
    useAuth(); // Get user info from context

  if (!isAuthenticated) {
    return (
      <section className="full-screen-main-section">
        <p>Please log in to view your profile.</p>
      </section>
    );
  }

  // Helper function to calculate account age
  const calculateAccountAge = (userCreatedAt: string | null): string => {
    if (!userCreatedAt) return 'Unknown'; // Handle null case

    const createdDate = new Date(userCreatedAt);
    const now = new Date();
    const diffInMs = now.getTime() - createdDate.getTime();
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24)); // Convert ms to days

    if (diffInDays < 90) {
      return `${diffInDays} day${diffInDays !== 1 ? 's' : ''} old`;
    }

    const diffInYears = diffInMs / (1000 * 60 * 60 * 24 * 365); // Convert ms to years

    if (diffInYears < 1) {
      const diffInMonths = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 30)); // Convert ms to months
      return `${diffInMonths} month${diffInMonths !== 1 ? 's' : ''} old`;
    }

    return `${Math.floor(diffInYears)} year${
      Math.floor(diffInYears) !== 1 ? 's' : ''
    } old`;
  };

  // Handle cases where userCreatedAt might be null
  const accountAge = calculateAccountAge(userCreatedAt);
  const formattedCreatedAt = userCreatedAt
    ? new Date(userCreatedAt).toLocaleDateString()
    : 'Unknown';

  return (
    <section className="full-screen-main-section">
      <div className="profile-container">
        {/* Profile Header */}
        <div className="profile-header">
          <img
            src="/images/placeholder_user_profile_picture.png"
            alt="Profile"
            className="profile-picture"
          />
          <div className="basic-info">
            <h2>
              <FontAwesomeIcon icon={faUser} /> {username || 'Unknown User'}
            </h2>
            <p>
              <FontAwesomeIcon icon={faEnvelope} /> {email || 'Unknown Email'}
            </p>
          </div>
        </div>

        {/* User Statistics */}
        <div className="user-stats">
          <div className="stat-card">
            <FontAwesomeIcon icon={faBirthdayCake} />
            <div className="stat-info">
              <p>Created on: {formattedCreatedAt}</p>
              <p>Account Age: {accountAge}</p>
            </div>
          </div>
        </div>

        {/* Recent Activity */}
        {/* <div className="recent-activity">
          <h3>
            <FontAwesomeIcon icon={faBell} /> Recent Activity
          </h3>
          <ul>
            <li>Posted a new article on Fast Food Paychecks.</li>
            <li>Commented on "December Financial Goals".</li>
            <li>Updated profile picture.</li>
            <li>Liked the post "January Blues: Financial Goals for 2025".</li>
          </ul>
        </div> */}

        {/* Account Settings */}
        <div className="account-settings">
          <h3>
            <FontAwesomeIcon icon={faCog} /> Account Settings
          </h3>
          <ul>
            {/* <li>
              <a href="/settings/profile">
                <FontAwesomeIcon icon={faUser} /> Edit Profile
              </a>
            </li> */}
            {/* <li>
              <a href="/settings/password">
                <FontAwesomeIcon icon={faCog} /> Change Password
              </a>
            </li> */}
            <li>
              <Button
                text="Logout"
                className="dark"
                handleButtonClick={logout}
              />
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Profile;
