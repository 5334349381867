// Blog.tsx
import './Blog.css';
import React from 'react';
import { useParams } from 'react-router-dom';
import BlogList from './BlogList/BlogList';
import BlogPost from './BlogPosts/BlogPost';
import BlogSideMenu from './BlogSideMenu/BlogSideMenu';
import MobileSideBar from './../../components/MobileSideBar/MobileSideBar';

const Blog: React.FC = () => {
  const { month, postName } = useParams();

  if (postName && month) {
    return (
      <>
        <MobileSideBar />
        <div className="blog-container">
          <BlogSideMenu />
          <div className="blog-content">
            <BlogPost month={month} postName={postName} />
          </div>
        </div>
      </>
    );
  } else {
    // Display the list of blog posts, filtered by month if provided
    return (
      <>
        <MobileSideBar />
        <BlogList month={month} />
      </>
    );
  }
};

export default Blog;
