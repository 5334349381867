import React, { useContext } from 'react';
import { Accordion, ProgressBar } from 'react-bootstrap';
import { AppContext } from '../../../AppContext';
import './IncomeLivabilityResults.css';
import ScoreBar from './../ScoreBar/ScoreBar';
import { FaInfoCircle, FaClipboardList } from 'react-icons/fa'; // Make sure to install react-icons or use another icon library
import { Tooltip } from 'react-tooltip';
import { formatCurrency } from './../../Common/Currencies';

const ResultsDisplay: React.FC<{
  results: any;
  currencySymbol: string;
  country: string;
  age: Number | '';
}> = ({ results, currencySymbol, country, age }) => {
  const { overall, income, costs } = results.results;
  // Context
  const appContext = useContext(AppContext);
  if (!appContext) {
    throw new Error('Header must be used within an AppProvider');
  }
  const {
    isMobileMenuBarOpen,
    setIsMobileMenuBarOpen,
    isMobile1100,
    setIsMobile1100,
    isMobile625,
    setIsMobile625,
  } = appContext;

  const getAssessmentMessage = (grade: string) => {
    switch (grade) {
      case 'A+':
        return 'Outstanding! Your financial health is exceptional — your financial future is exceptionally bright!';
      case 'A':
        return 'Excellent! Your financial health is outstanding. Keep up the exceptional work and continue to build on this strong foundation!';
      case 'B':
        return 'Great job! You are on the right track. With a few adjustments, you can further enhance your financial well-being!';
      case 'C':
        return "You're doing well! Your financial situation is stable, and there are actionable steps you can take to increase your savings and investments.";
      case 'D':
        return "You're making progress! Taking a closer look at your expenses can help you strengthen your financial stability.";
      case 'E':
        return "There's room for improvement to enhance your financial health.";
      case 'F':
        return "It's time to take action. Utilize the recommendations to create a plan that improves your financial situation.";
      default:
        return 'Assessment unavailable.';
    }
  };

  const renderGrade = (grade: string) => {
    let gradeClass = '';
    switch (grade) {
      case 'A+':
        gradeClass = 'text-success';
        break;
      case 'A':
        gradeClass = 'text-success';
        break;
      case 'B':
        gradeClass = 'text-success';
        break;
      case 'C':
        gradeClass = 'text-success';
        break;
      case 'D':
        gradeClass = 'text-warning';
        break;
      case 'E':
        gradeClass = 'text-warning';
        break;
      case 'F':
        gradeClass = 'text-danger';
        break;
      default:
        gradeClass = '';
    }
    return (
      <strong>
        <span className={gradeClass}>{grade}</span>
      </strong>
    );
  };

  return (
    <div className={`results-container ${isMobile1100 ? 'mobile' : ''}`}>
      <div className="benchmark-container-title">
        <h2>
          Income Livability Results for an Adult Aged{' '}
          <span className="underline">{age.toString()}</span> Living in{' '}
          <span className="underline">{country}</span> on a net income of{' '}
          <span className="underline">
            {formatCurrency(Number(income.details.local.income), 0)}
            {currencySymbol}
          </span>
          <span style={{ fontSize: '0.7em' }}>/mo</span>
        </h2>
      </div>
      <div className="overall-score">
        <strong>Overall Grade: {renderGrade(overall.grade)}</strong>
        <ScoreBar score={overall.score} isOverall={true} />
      </div>
      <div className="form-row">
        <div className="info-container">
          <FaClipboardList className="info-icon" />
          <div className="info-text">{getAssessmentMessage(overall.grade)}</div>
        </div>
      </div>
      <Accordion>
        <Accordion.Item eventKey="0" className="accordion-item">
          <Accordion.Header>
            <div className="accordion-header-content">
              <div>
                <strong>Income Grade:</strong> {renderGrade(income.grade)}
              </div>
              <div className="score-bar-wrapper">
                <ScoreBar score={income.score} />
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <h5>Local Income Breakdown</h5>
              <strong>Grade:</strong> {renderGrade(income.details.local.grade)}
              <p>
                <strong>Average Net Income for {country}:</strong>{' '}
                {currencySymbol}{' '}
                {income.details.local.local_avg_net_salary_benchmark.toFixed(2)}{' '}
                / month
              </p>
              <p>
                <strong>Your Net Income:</strong> {currencySymbol}{' '}
                {income.details.local.income.toFixed(2)} / month
              </p>
              <p>
                Your net income corresponds to{' '}
                <strong>
                  {(income.details.local.ratio * 100).toFixed(0)}%
                </strong>{' '}
                of the national average in {country}. Your income is{' '}
                <strong>
                  {income.details.local.ratio < 1 ? 'below' : 'above'}
                </strong>{' '}
                average.
              </p>
              <ScoreBar score={income.details.local.score} />
            </div>
            <div>
              <div className="accordion-body-title-with-icons">
                <h5>Global Income Breakdown from Top Economies</h5>
                <FaInfoCircle
                  data-tooltip-id="infoTooltip"
                  className="info-icon"
                  size="15px"
                />
                <Tooltip id="infoTooltip" place="top">
                  This list includes countries recognized for high living
                  standards and strong economies (average net salaries ranging
                  from $2,126 to $6,312):
                  <br />
                  <ul>
                    <li>
                      <strong>Americas:</strong> Canada, United States
                    </li>
                    <li>
                      <strong>Europe:</strong> Austria, Belgium, Denmark,
                      Finland, France, Germany, Ireland, Luxembourg,
                      Netherlands, Norway, Sweden, Switzerland, United Kingdom
                    </li>
                    <li>
                      <strong>Middle East:</strong> Bahrain, Kuwait, Oman,
                      Qatar, Saudi Arabia, United Arab Emirates
                    </li>
                    <li>
                      <strong>Asia-Pacific:</strong> Hong Kong (China), Japan,
                      New Zealand, Singapore, South Korea
                    </li>
                  </ul>
                </Tooltip>
              </div>
              <strong>Grade:</strong> {renderGrade(income.details.global.grade)}
              <p>
                <strong>
                  Average Net Income from the World's Leading Economies:
                </strong>{' '}
                {currencySymbol}
                {income.details.global.global_std_avg_salary_benchmark.toFixed(
                  2
                )}{' '}
                / month
              </p>
              <p>
                <strong>Your Net Income:</strong> {currencySymbol}
                {income.details.global.income.toFixed(2)} / month
              </p>
              <p>
                Your net income is{' '}
                <strong>
                  {(income.details.global.ratio * 100).toFixed(0)}%
                </strong>
                of the average in top global economies. This makes your income{' '}
                <strong>
                  {income.details.global.ratio < 1 ? 'below' : 'above'}
                </strong>{' '}
                the global average, meaning it is{' '}
                <strong>
                  {income.details.global.ratio < 1
                    ? 'less competitive'
                    : 'highly competitive'}
                </strong>{' '}
                worldwide{income.details.global.ratio < 1 ? '.' : '!'}
              </p>
              <ScoreBar score={income.details.global.score} />
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <div className="accordion-header-content">
              <div>
                <strong>Costs Grade: </strong>
                {renderGrade(costs.grade)}
              </div>
              <div className="score-bar-wrapper">
                <ScoreBar score={costs.score} />
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <h5>Living Costs Breakdown</h5>
              <strong>Grade:</strong>{' '}
              {renderGrade(costs.details.total_living_costs.grade)}
              <p>
                <strong>Your Net Income:</strong> {currencySymbol}{' '}
                {income.details.local.income.toFixed(2)} / month
              </p>
              <p>
                <strong>Your Total Living Costs:</strong> {currencySymbol}{' '}
                {costs.details.total_living_costs.cost_monthly.toFixed(2)} /
                month
              </p>
              <p>
                <strong>Your Disposable Income:</strong> {currencySymbol}{' '}
                {costs.details.total_living_costs.surplus_monthly.toFixed(2)} /
                month
              </p>
              <p>
                Your total living costs make up{' '}
                <strong>
                  {(costs.details.total_living_costs.ratio * 100).toFixed(0)}%
                </strong>{' '}
                of your net income in {country}. This means your costs are{' '}
                <strong>
                  {costs.details.total_living_costs.ratio > 0.6
                    ? 'above'
                    : 'within'}
                </strong>{' '}
                the recommended limit of <strong>60%</strong> for maintaining
                healthy and sustainable living expenses. This leaves you with a{' '}
                <strong
                  style={{
                    color:
                      costs.details.total_living_costs.surplus_monthly < 0
                        ? 'red'
                        : 'inherit',
                  }}
                >
                  {costs.details.total_living_costs.surplus_monthly < 0
                    ? 'negative'
                    : ''}
                </strong>{' '}
                surplus of{' '}
                <strong
                  style={{
                    color:
                      costs.details.total_living_costs.surplus_monthly < 0
                        ? 'red'
                        : 'inherit',
                  }}
                >
                  {' '}
                  {currencySymbol}{' '}
                  {costs.details.total_living_costs.surplus_monthly.toFixed(2)}{' '}
                </strong>
                / month.
              </p>
              <ScoreBar score={costs.details.total_living_costs.score} />
            </div>
            <hr
              style={{
                border: 'none',
                borderTop: '3px solid #000',
                margin: '25px 0',
              }}
            />
            <div>
              <h5>Rent Costs</h5>
              <strong>Grade:</strong> {renderGrade(costs.details.rent.grade)}
              <p>
                Your rent costs {currencySymbol}
                {costs.details.rent.rent_cost_monthly.toFixed(2)} / month. This
                corresponds to
                <strong>
                  {' '}
                  {(
                    (100 * costs.details.rent.rent_cost_monthly) /
                    costs.details.total_living_costs.cost_monthly
                  ).toFixed(0)}
                  %{' '}
                </strong>
                of your total living costs.
              </p>
              <ScoreBar score={costs.details.rent.score} />
            </div>
            <div>
              <h5>Food Costs</h5>
              <strong>Grade:</strong> {renderGrade(costs.details.food.grade)}
              <p>
                Your food costs {currencySymbol}
                {costs.details.food.cost_monthly.toFixed(2)} / month. This
                corresponds to
                <strong>
                  {' '}
                  {(
                    (100 * costs.details.food.cost_monthly) /
                    costs.details.total_living_costs.cost_monthly
                  ).toFixed(0)}
                  %{' '}
                </strong>
                of your total living costs.
              </p>
              <ScoreBar
                score={costs.details.food.score}
                showPerformanceMessages={false}
              />
            </div>
            <div>
              <div className="accordion-body-title-with-icons">
                <h5>Utilities Costs</h5>
                <FaInfoCircle
                  data-tooltip-id="infoTooltipUtilitiesCosts"
                  className="info-icon"
                  size="15px"
                />
                <Tooltip id="infoTooltipUtilitiesCosts" place="top-start">
                  Utilities cost estimation includes price statistics for:
                  Electricity, Water, Heating, Internet, and Mobile Phone
                  Services.
                </Tooltip>
              </div>
              <strong>Grade:</strong>{' '}
              {renderGrade(costs.details.utilities.grade)}
              <p>
                Your utilities costs {currencySymbol}
                {costs.details.utilities.cost_monthly.toFixed(2)} / month. This
                corresponds to
                <strong>
                  {' '}
                  {(
                    (100 * costs.details.utilities.cost_monthly) /
                    costs.details.total_living_costs.cost_monthly
                  ).toFixed(0)}
                  %{' '}
                </strong>
                of your total living costs.
              </p>
              <ScoreBar
                score={costs.details.utilities.score}
                showPerformanceMessages={false}
              />
            </div>

            <div>
              <div className="accordion-body-title-with-icons">
                <h5>Transportation Costs</h5>
                <FaInfoCircle
                  data-tooltip-id="infoTooltipTransportCosts"
                  className="info-icon"
                  size="15px"
                />
                <Tooltip id="infoTooltipTransportCosts" place="top-start">
                  Transport costs are calculated based on the country and age,
                  providing statistical approximations that considers both
                  gasoline expenses and public transportation fees.
                </Tooltip>
              </div>
              <strong>Grade:</strong>{' '}
              {renderGrade(costs.details.transport.grade)}
              <p>
                Your transportation costs {currencySymbol}
                {costs.details.transport.cost_monthly.toFixed(2)} / month. This
                corresponds to
                <strong>
                  {' '}
                  {(
                    (100 * costs.details.transport.cost_monthly) /
                    costs.details.total_living_costs.cost_monthly
                  ).toFixed(0)}
                  %{' '}
                </strong>
                of your total living costs.
              </p>
              <ScoreBar
                score={costs.details.transport.score}
                showPerformanceMessages={false}
              />
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="form-row">
        <div className="info-container">
          <FaInfoCircle className="info-icon" />
          <div className="info-text-small">
            The percentage score (0 to 100%) that is presented reflects an
            abstract performance measurement score: 95%+ is Exceptional (A+),
            90-94% is Excellent (A), 80-89% is Great (B), 70-79% is Good (C),
            60-69% is Okay (D), 50-59% is Poor (E), and below 50% is Very Poor
            (F).
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultsDisplay;
