import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register the necessary components for Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface BarChartProps {
    data: { country: string; overall: number }[];
    title?: string;
    barThickness: number;
    indexAxis?: "y" | "x"; 
}


// The existing getGradientColor function
const getGradientColor = (score: number) => {
    const badRed = { r: 255, g: 70, b: 70 }; // Solid Red
    const neutralYellow = { r: 255, g: 215, b: 0 }; // Bright Yellow
    const goodGreen = { r: 0, g: 150, b: 50 }; // Solid Green
    const middlePoint1 = 0.25;
    const middlePoint2 = 0.5;
    const middlePoint3 = 0.9;

    let r, g, b;

    if (score <= middlePoint1) {
        r = badRed.r;
        g = badRed.g;
        b = badRed.b;
    } else if (score <= middlePoint2) {
        const ratio = (score - middlePoint1) / (middlePoint2 - middlePoint1);
        r = Math.round(badRed.r + ratio * (neutralYellow.r - badRed.r));
        g = Math.round(badRed.g + ratio * (neutralYellow.g - badRed.g));
        b = Math.round(badRed.b + ratio * (neutralYellow.b - badRed.b));
    } else if (score <= middlePoint3) {
        const ratio = (score - middlePoint2) / (middlePoint3 - middlePoint2);
        r = Math.round(neutralYellow.r + ratio * (goodGreen.r - neutralYellow.r));
        g = Math.round(neutralYellow.g + ratio * (goodGreen.g - neutralYellow.g));
        b = Math.round(neutralYellow.b + ratio * (goodGreen.b - neutralYellow.b));
    } else {
        r = goodGreen.r;
        g = goodGreen.g;
        b = goodGreen.b;
    }

    return `rgb(${r}, ${g}, ${b})`;
};

const BarChart: React.FC<BarChartProps> = ({ data, title="", barThickness=20, indexAxis ="x" }) => {
    const chartData = {
        labels: data.map(item => item.country),
        datasets: [
            {
                label: 'Income Livability Index',
                data: data.map(item => item.overall),
                barThickness: barThickness,
                maxBarThickness: 25,
                backgroundColor: data.map(item => getGradientColor(item.overall )), // Normalize score if it's out of 100
            },
        ],
    };

    const options = {
        responsive: true,
        indexAxis: indexAxis,
        
        scales: {
            y: {
                ticks: {
                    autoSkip: false,
                },
            },
        },
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom' as const,
            },
            title: {
                display: title === "" ? false : true,
                text: title,
            },
        },
    };

    return <Bar data={chartData} options={options} />;
};

export default BarChart;
