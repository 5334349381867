import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import Link from react-router-dom
import zxcvbn from 'zxcvbn'; // Import zxcvbn for password strength evaluation
import './Signup.css';
import PasswordStrengthIndicator from './PasswordStrengthIndicator/PasswordStrengthIndicator';
import { sendBackendRequest } from './../../Services/BackendRequestClient/BackendRequestClient';
import MobileSideBar from './../../components/MobileSideBar/MobileSideBar';

const SignUp: React.FC = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [passwordScore, setPasswordScore] = useState(0);
  const navigate = useNavigate();

  // This function will handle signup logic
  const handleSignup = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validation Rules
    if (username.length < 5) {
      setError('Username must be at least 5 characters long.');
      return;
    }

    if (username.length > 20) {
      setError('Username must be maximum 20 characters long.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Invalid email format.');
      return;
    }

    if (password.length < 6) {
      setError('Password must be at least 6 characters long.');
      return;
    }

    if (
      !/[A-Z]/.test(password) ||
      !/[0-9]/.test(password) ||
      !/[!@#$%^&*]/.test(password)
    ) {
      setError(
        'Password must contain at least one uppercase letter, one number, and one special character.'
      );
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match!');
      return;
    }

    try {
      setError('');
      setLoading(true);

      const data = await sendBackendRequest({
        rel_endpoint: '/users',
        method: 'POST',
        body: { username, email, password },
      });

      setSuccess('Account created successfully!');
      setUsername('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');

      setTimeout(() => {
        navigate('/login');
      }, 500);
    } catch (err) {
      setError('Signup failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Function to handle password change and evaluate strength
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    const result = zxcvbn(newPassword); // Evaluate password strength
    setPasswordScore(result.score); // Set the password score (0-4)
  };

  return (
    <>
      <MobileSideBar />
      <section className="signup-container-section">
        <div className="signup-container">
          <h2>Create Your Account</h2>

          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>}

          <form onSubmit={handleSignup} className="signup-form">
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                placeholder="Enter your username"
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter your email"
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
                required
                placeholder="Enter your password"
              />

              {/* Conditionally render PasswordStrengthIndicator */}
              {password && <PasswordStrengthIndicator score={passwordScore} />}
            </div>

            <div className="form-group">
              <label htmlFor="confirm-password">Confirm Password</label>
              <input
                type="password"
                id="confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                placeholder="Confirm your password"
              />
            </div>

            <button type="submit" className="signup-button" disabled={loading}>
              {loading ? 'Signing up...' : 'Sign Up'}
            </button>
          </form>

          {/* Add a link to the login page */}
          <div className="redirect-login">
            <p>
              Already have an account? <Link to="/login">Log In</Link>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUp;
