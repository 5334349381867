import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import { useAuth } from '../../pages/Login/AuthContext';
import Logo from '../../components/Logo/Logo'
import LogoHeader from './Components/LogoHeader'
import Button from '../../components/Button/Button';
import ButtonDark from '../../components/Button/ButtonDark';
import { AppContext } from './../../AppContext';

const Header: React.FC = () => {

  // Context
  const { isAuthenticated, username, logout } = useAuth();
  const appContext = useContext(AppContext);
  if (!appContext) {
    throw new Error("Header must be used within an AppProvider");
  }
  const { isMobileMenuBarOpen, setIsMobileMenuBarOpen } = appContext;

  // States
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobile625, setIsMobile625] = useState(window.innerWidth <= 625);
  const [isMobile768, setIsMobile768] = useState(window.innerWidth <= 768);
  const [isMobile875, setIsMobile875] = useState(window.innerWidth <= 875);
  const navigate = useNavigate()
  const handleNavigation = (path: string) => {
    navigate(path);
  };

  const handleResize = () => {
    setIsMobile625(window.innerWidth <= 625);
    setIsMobile768(window.innerWidth <= 768);
    setIsMobile875(window.innerWidth <= 875);
  };


  // Set up event listener on mount to track window size changes
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const toggleMenuDropdown = () => {
    setIsMobileMenuBarOpen(!isMobileMenuBarOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <header className="header-container">
      <div className="header-container-inner">


        {/* 2 - MOBILE - Menu Bar */}
        <div className={`menu-toggle ${isMobile875 ? "" : "mobile-hidden"} dropdown`}>

          <div
            className="menu-toggle-button"
            onClick={toggleMenuDropdown}
            aria-haspopup="true"
            aria-expanded={isDropdownOpen}
          >
            ☰
          </div>

        </div>

        {/* 1 - Logo */}

        <a
          aria-current="page"
          className={`header-container-nav-logo-styled ${isMobile875 ? "mobile" : ""}`}
          title="My Personal Finances"
          target="_self"
          href="/"
        >
          <LogoHeader />
        </a>
        {/* 3 - DESKTOP - Unordered List Menu Bar */}
        <ul
          role="menubar"
          className={`header-menu-bar-ul ${!isMobile875 ? "" : "mobile-hidden"}`}
        >

          {/* 2.2 - PUBLIC MENU BAR */}

          <li className="header-menu-bar-li dropdown">
            <a
              className="header-menu-bar-li-button"
              onClick={toggleDropdown}
              aria-haspopup="true"
              aria-expanded={isDropdownOpen}
            >
              Benchmarks
            </a>
            {isDropdownOpen && (
              <ul className="dropdown-menu">
                <li><a href="/benchmarks/income-livability" className="dropdown-item">Income Livability Index 🌍📊
                </a></li>
              </ul>
            )}
          </li>

          <li className="header-menu-bar-li dropdown">
            <a
              className="header-menu-bar-li-button"
              onClick={toggleDropdown}
              aria-haspopup="true"
              aria-expanded={isDropdownOpen}
            >
              Calculators
            </a>
            {isDropdownOpen && (

              <ul className="dropdown-menu">

                <li><a href="/calculators/income-livability" className="dropdown-item">Income Livability Calculator 🏠</a></li>
                <li><a href="/calculators/fx" className="dropdown-item">Currency Converter 💱</a></li>
                {/* <li>
                  <a href={isAuthenticated ? "/calculators/advanced-personal-finances-calculator" : "/login"} className="dropdown-item">
                    <span style={{ color: 'var(--bs-link-hover-color)' }}><strong>(Premium)</strong></span> Advanced Personal Finances Calculator 📝
                  </a>
                </li> */}

                {/* <li><a href="/calculators/income-livability" className="dropdown-item">(Premium 🔒) Advanced Personal Finances Calculator 📝 </a></li> */}
              </ul>
            )}
          </li>

            <li className="header-menu-bar-li dropdown">
              <a
                className="header-menu-bar-li-button"
                onClick={() => handleNavigation('/blog')}
                aria-haspopup="true"
              >
                Blog
              </a>
            </li>


          {/* 2.1 - AUTHENTICATED ONLY MENU BAR */}
          {isAuthenticated ? (
            <>
              <li className="header-menu-bar-li">
                <a href="/profile" className="header-menu-bar-li-button">My Profile</a>  {/*AUTHENTICATED ONLY */}
              </li>

            </>
          ) : (null)}
        </ul>

        {/*3 - NOT AUTHENTICATED - LOGIN AND SIGNUP*/}
        {!isAuthenticated && !isMobile625 ? (
          <>
            <div className={`header-container-login-div ${!isMobile625 ? "" : "mobile-hidden"}`}>
              <ButtonDark text="Login" handleButtonClick={() => handleNavigation('/login')} />
              {/* <a className='header-container-login-button' onClick={() => handleNavigation('/login')} title="Login here">
                  Login
                </a> */}
            </div>

            <div className={`header-container-signup-div ${!isMobile625 ? "" : "mobile-hidden"}`}>
              {/* // onClick={() => handleNavigation('/signup')} title="Sign up here">
              // Sign up */}
              <Button text="Sign up" handleButtonClick={() => handleNavigation('/signup')} />
            </div>
          </>
        ) : (null)}

        {/*4 - AUTHENTICATED - LOGOUT BUTTON  */}
        {isAuthenticated && !isMobile625 ? (
          <div className={`header-container-signup-div ${!isMobile625 ? "" : "mobile-hidden"}`}>
            <ButtonDark text="Logout" handleButtonClick={logout} />
          </div>
        ) : (null)
        }

      </div>
    </header >

  );
};

export default Header;
