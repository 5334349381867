import React, { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';

interface LoadingProps {
    loading: boolean;  // Determines if the loading spinner should be displayed
    size?: number;     // Optional size for the spinner
    color?: string;    // Optional color for the spinner
}

const Loading: React.FC<LoadingProps> = ({ loading, size = 60, color = "#123abc" }) => {
    const [loadingText, setLoadingText] = useState("Loading."); // Initialize loading text
    const dots = ['.', '..', '...']; // Array of dots to iterate through
    let dotIndex = 0; // Index to track which dot pattern to show

    useEffect(() => {
        if (!loading) return; // Exit if not loading
        
        const intervalId = setInterval(() => {
            dotIndex = (dotIndex + 1) % dots.length; // Cycle through the dots
            setLoadingText(`Loading${dots[dotIndex]}`); // Update the loading text
        }, 333); // Update every second

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [loading]);

    return (
        <div style={overlayStyles}>
            {loading && (
                <>
                    <ClipLoader size={size} color={color} loading={loading} />
                    <p style={textStyles}>{loadingText}</p>
                </>
            )}
        </div>
    );
};

// Using React.CSSProperties for type safety
const overlayStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', // Full height for centering
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional background for overlay
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999, // High z-index to cover other elements
};

const textStyles: React.CSSProperties = {
    marginTop: '10px',
    fontSize: '18px',
    color: '#123abc',
};

export default Loading;
