export interface CountryData {
  country: string;
  country_code: string;
  overall: number;
  median_salary_gross_yr: number;
  currency: string;
  sources: string[];
}

export interface IncomeLivabilityBenchmarkData {
  results: {
    software_engineer: CountryData[];
    fast_food_worker: CountryData[];
  };
}

export type JobTitle = 'Fast Food Worker' | 'Software Engineer';
export const defJobTitle: JobTitle = 'Fast Food Worker';
// Extend as needed

export const incomeLivabilityBenchmarkData_2024_11_02: IncomeLivabilityBenchmarkData =
  {
    results: {
      software_engineer: [
        {
          country: 'United States',
          country_code: 'US',
          overall: 0.922,
          median_salary_gross_yr: 169442.25,
          currency: 'USD',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'United Kingdom',
          country_code: 'GB',
          overall: 0.89521,
          median_salary_gross_yr: 81074.71,
          currency: 'GBP',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Portugal',
          country_code: 'PT',
          overall: 0.70677,
          median_salary_gross_yr: 47644.23,
          currency: 'EUR',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Denmark',
          country_code: 'DK',
          overall: 0.85674,
          median_salary_gross_yr: 673277.54,
          currency: 'DKK',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Switzerland',
          country_code: 'CH',
          overall: 0.89143,
          median_salary_gross_yr: 138074.8,
          currency: 'CHF',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Luxembourg',
          country_code: 'LU',
          overall: 0.76928,
          median_salary_gross_yr: 99276.4,
          currency: 'EUR',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'China',
          country_code: 'CN',
          overall: 0.91548,
          median_salary_gross_yr: 496527.5,
          currency: 'CNY',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Japan',
          country_code: 'JP',
          overall: 0.86521,
          median_salary_gross_yr: 9050906.15,
          currency: 'JPY',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Germany',
          country_code: 'DE',
          overall: 0.72598,
          median_salary_gross_yr: 77372.34,
          currency: 'EUR',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'France',
          country_code: 'FR',
          overall: 0.80893,
          median_salary_gross_yr: 59091.0,
          currency: 'EUR',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Canada',
          country_code: 'CA',
          overall: 0.87542,
          median_salary_gross_yr: 134399.69,
          currency: 'CAD',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Australia',
          country_code: 'AU',
          overall: 0.8731,
          median_salary_gross_yr: 146025.12,
          currency: 'AUD',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Italy',
          country_code: 'IT',
          overall: 0.68176,
          median_salary_gross_yr: 38518.32,
          currency: 'EUR',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'South Korea',
          country_code: 'KR',
          overall: 0.8848,
          median_salary_gross_yr: 91328342.11,
          currency: 'KRW',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Spain',
          country_code: 'ES',
          overall: 0.82493,
          median_salary_gross_yr: 54062.48,
          currency: 'EUR',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Mexico',
          country_code: 'MX',
          overall: 0.85843,
          median_salary_gross_yr: 954164.92,
          currency: 'MXN',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Brazil',
          country_code: 'BR',
          overall: 0.81862,
          median_salary_gross_yr: 167861.33,
          currency: 'BRL',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Indonesia',
          country_code: 'ID',
          overall: 0.69162,
          median_salary_gross_yr: 253599623.75,
          currency: 'IDR',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Netherlands',
          country_code: 'NL',
          overall: 0.79875,
          median_salary_gross_yr: 87624.14,
          currency: 'EUR',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Turkey',
          country_code: 'TR',
          overall: 0.68104,
          median_salary_gross_yr: 1032282.42,
          currency: 'TRY',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Saudi Arabia',
          country_code: 'SA',
          overall: 0.87451,
          median_salary_gross_yr: 178282.11,
          currency: 'SAR',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Sweden',
          country_code: 'SE',
          overall: 0.80428,
          median_salary_gross_yr: 681202.89,
          currency: 'SEK',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Norway',
          country_code: 'NO',
          overall: 0.82859,
          median_salary_gross_yr: 843065.0,
          currency: 'NOK',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Belgium',
          country_code: 'BE',
          overall: 0.65831,
          median_salary_gross_yr: 57742.77,
          currency: 'EUR',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Austria',
          country_code: 'AT',
          overall: 0.7863,
          median_salary_gross_yr: 65314.25,
          currency: 'EUR',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Finland',
          country_code: 'FI',
          overall: 0.78809,
          median_salary_gross_yr: 61070.19,
          currency: 'EUR',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Ireland',
          country_code: 'IE',
          overall: 0.80271,
          median_salary_gross_yr: 94691.71,
          currency: 'EUR',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Taiwan',
          country_code: 'TW',
          overall: 0.87823,
          median_salary_gross_yr: 1549748.58,
          currency: 'TWD',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'South Africa',
          country_code: 'ZA',
          overall: 0.84024,
          median_salary_gross_yr: 786092.47,
          currency: 'ZAR',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Poland',
          country_code: 'PL',
          overall: 0.84221,
          median_salary_gross_yr: 246093.32,
          currency: 'PLN',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Czech Republic',
          country_code: 'CZ',
          overall: 0.86387,
          median_salary_gross_yr: 1368464.2,
          currency: 'CZK',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Greece',
          country_code: 'GR',
          overall: 0.71387,
          median_salary_gross_yr: 31292.08,
          currency: 'EUR',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Hungary',
          country_code: 'HU',
          overall: 0.85128,
          median_salary_gross_yr: 15289153.75,
          currency: 'HUF',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Romania',
          country_code: 'RO',
          overall: 0.84142,
          median_salary_gross_yr: 234598.56,
          currency: 'RON',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'New Zealand',
          country_code: 'NZ',
          overall: 0.85723,
          median_salary_gross_yr: 126690.86,
          currency: 'NZD',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Israel',
          country_code: 'IL',
          overall: 0.91375,
          median_salary_gross_yr: 477033.35,
          currency: 'ILS',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Malaysia',
          country_code: 'MY',
          overall: 0.7897,
          median_salary_gross_yr: 108891.7,
          currency: 'MYR',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Chile',
          country_code: 'CL',
          overall: 0.82128,
          median_salary_gross_yr: 32081157.0,
          currency: 'CLP',
          sources: ['levelsio'],
        },
        {
          country: 'Slovakia',
          country_code: 'SK',
          overall: 0.80341,
          median_salary_gross_yr: 41779.42,
          currency: 'EUR',
          sources: ['manual', 'levelsio'],
        },
        {
          country: 'Singapore',
          country_code: 'SG',
          overall: 0.7696,
          median_salary_gross_yr: 131882.53,
          currency: 'SGD',
          sources: ['manual', 'levelsio'],
        },
      ],
      fast_food_worker: [
        {
          country: 'United States',
          country_code: 'US',
          overall: 0.33661,
          median_salary_gross_yr: 28642.5,
          currency: 'USD',
          sources: ['manual'],
        },
        {
          country: 'United Kingdom',
          country_code: 'GB',
          overall: 0.40416,
          median_salary_gross_yr: 21029.11,
          currency: 'GBP',
          sources: ['manual'],
        },
        {
          country: 'Portugal',
          country_code: 'PT',
          overall: 0.24766,
          median_salary_gross_yr: 10881.67,
          currency: 'EUR',
          sources: ['manual'],
        },
        {
          country: 'Denmark',
          country_code: 'DK',
          overall: 0.46009,
          median_salary_gross_yr: 268898.0,
          currency: 'DKK',
          sources: ['manual'],
        },
        {
          country: 'Switzerland',
          country_code: 'CH',
          overall: 0.47506,
          median_salary_gross_yr: 43810.0,
          currency: 'CHF',
          sources: ['manual'],
        },
        {
          country: 'Luxembourg',
          country_code: 'LU',
          overall: 0.34805,
          median_salary_gross_yr: 29351.7,
          currency: 'EUR',
          sources: ['manual'],
        },
        {
          country: 'China',
          country_code: 'CN',
          overall: 0.18115,
          median_salary_gross_yr: 43635.62,
          currency: 'CNY',
          sources: ['manual'],
        },
        {
          country: 'Japan',
          country_code: 'JP',
          overall: 0.32061,
          median_salary_gross_yr: 2263415.38,
          currency: 'JPY',
          sources: ['manual'],
        },
        {
          country: 'Germany',
          country_code: 'DE',
          overall: 0.29451,
          median_salary_gross_yr: 21909.78,
          currency: 'EUR',
          sources: ['manual'],
        },
        {
          country: 'France',
          country_code: 'FR',
          overall: 0.3819,
          median_salary_gross_yr: 21181.11,
          currency: 'EUR',
          sources: ['manual'],
        },
        {
          country: 'Canada',
          country_code: 'CA',
          overall: 0.323,
          median_salary_gross_yr: 31204.2,
          currency: 'CAD',
          sources: ['manual'],
        },
        {
          country: 'Australia',
          country_code: 'AU',
          overall: 0.39244,
          median_salary_gross_yr: 40958.09,
          currency: 'AUD',
          sources: ['manual'],
        },
        {
          country: 'Italy',
          country_code: 'IT',
          overall: 0.34118,
          median_salary_gross_yr: 17958.0,
          currency: 'EUR',
          sources: ['manual'],
        },
        {
          country: 'South Korea',
          country_code: 'KR',
          overall: 0.33847,
          median_salary_gross_yr: 22009875.0,
          currency: 'KRW',
          sources: ['manual'],
        },
        {
          country: 'Spain',
          country_code: 'ES',
          overall: 0.35032,
          median_salary_gross_yr: 16213.64,
          currency: 'EUR',
          sources: ['manual'],
        },
        {
          country: 'Mexico',
          country_code: 'MX',
          overall: 0.17924,
          median_salary_gross_yr: 94693.33,
          currency: 'MXN',
          sources: ['manual'],
        },
        {
          country: 'Brazil',
          country_code: 'BR',
          overall: 0.20813,
          median_salary_gross_yr: 31974.6,
          currency: 'BRL',
          sources: ['manual'],
        },
        {
          country: 'Indonesia',
          country_code: 'ID',
          overall: 0.25118,
          median_salary_gross_yr: 89396416.0,
          currency: 'IDR',
          sources: ['manual'],
        },
        {
          country: 'Netherlands',
          country_code: 'NL',
          overall: 0.30986,
          median_salary_gross_yr: 24910.45,
          currency: 'EUR',
          sources: ['manual'],
        },
        {
          country: 'Turkey',
          country_code: 'TR',
          overall: 0.30912,
          median_salary_gross_yr: 484800.0,
          currency: 'TRY',
          sources: ['manual'],
        },
        {
          country: 'Saudi Arabia',
          country_code: 'SA',
          overall: 0.3328,
          median_salary_gross_yr: 45798.58,
          currency: 'SAR',
          sources: ['manual'],
        },
        {
          country: 'Sweden',
          country_code: 'SE',
          overall: 0.34175,
          median_salary_gross_yr: 230722.92,
          currency: 'SEK',
          sources: ['manual'],
        },
        {
          country: 'Norway',
          country_code: 'NO',
          overall: 0.38438,
          median_salary_gross_yr: 285877.55,
          currency: 'NOK',
          sources: ['manual'],
        },
        {
          country: 'Belgium',
          country_code: 'BE',
          overall: 0.36501,
          median_salary_gross_yr: 26412.5,
          currency: 'EUR',
          sources: ['manual'],
        },
        {
          country: 'Austria',
          country_code: 'AT',
          overall: 0.41038,
          median_salary_gross_yr: 25848.12,
          currency: 'EUR',
          sources: ['manual'],
        },
        {
          country: 'Finland',
          country_code: 'FI',
          overall: 0.41289,
          median_salary_gross_yr: 26713.9,
          currency: 'EUR',
          sources: ['manual'],
        },
        {
          country: 'Ireland',
          country_code: 'IE',
          overall: 0.37624,
          median_salary_gross_yr: 26192.3,
          currency: 'EUR',
          sources: ['manual'],
        },
        {
          country: 'Taiwan',
          country_code: 'TW',
          overall: 0.31195,
          median_salary_gross_yr: 372700.0,
          currency: 'TWD',
          sources: ['manual'],
        },
        {
          country: 'South Africa',
          country_code: 'ZA',
          overall: 0.09426,
          median_salary_gross_yr: 63400.0,
          currency: 'ZAR',
          sources: ['manual'],
        },
        {
          country: 'Poland',
          country_code: 'PL',
          overall: 0.19235,
          median_salary_gross_yr: 36308.33,
          currency: 'PLN',
          sources: ['manual'],
        },
        {
          country: 'Czech Republic',
          country_code: 'CZ',
          overall: 0.26714,
          median_salary_gross_yr: 274257.78,
          currency: 'CZK',
          sources: ['manual'],
        },
        {
          country: 'Greece',
          country_code: 'GR',
          overall: 0.30503,
          median_salary_gross_yr: 11809.82,
          currency: 'EUR',
          sources: ['manual'],
        },
        {
          country: 'Hungary',
          country_code: 'HU',
          overall: 0.20336,
          median_salary_gross_yr: 2798300.0,
          currency: 'HUF',
          sources: ['manual'],
        },
        {
          country: 'Romania',
          country_code: 'RO',
          overall: 0.16505,
          median_salary_gross_yr: 36052.47,
          currency: 'RON',
          sources: ['manual'],
        },
        {
          country: 'New Zealand',
          country_code: 'NZ',
          overall: 0.42401,
          median_salary_gross_yr: 46973.87,
          currency: 'NZD',
          sources: ['manual'],
        },
        {
          country: 'Israel',
          country_code: 'IL',
          overall: 0.33276,
          median_salary_gross_yr: 74962.35,
          currency: 'ILS',
          sources: ['manual'],
        },
        {
          country: 'Malaysia',
          country_code: 'MY',
          overall: 0.14412,
          median_salary_gross_yr: 19314.5,
          currency: 'MYR',
          sources: ['manual'],
        },
        {
          country: 'Chile',
          country_code: 'CL',
          overall: 0.14603,
          median_salary_gross_yr: 4102.67,
          currency: 'USD',
          sources: ['manual'],
        },
        {
          country: 'Slovakia',
          country_code: 'SK',
          overall: 0.24157,
          median_salary_gross_yr: 10830.22,
          currency: 'EUR',
          sources: ['manual'],
        },
        {
          country: 'Singapore',
          country_code: 'SG',
          overall: 0.19298,
          median_salary_gross_yr: 16372.0,
          currency: 'SGD',
          sources: ['manual'],
        },
      ],
    },
  };
