//BlogPost.tsx
import './BlogPost.css'
import React from 'react';
import { BlogPostData, blogPostsData } from './BlogPostsData';

import BlogSideMenu from './../BlogSideMenu/BlogSideMenu';
import MobileSideBar from './../../../components/MobileSideBar/MobileSideBar';

interface BlogPostProps {
  month?: string;
  postName?: string;
}

const BlogPost: React.FC<BlogPostProps> = ({ month, postName }) => {
  const post = blogPostsData.find(
    (post) =>
      post.postName === postName && post.month.toLowerCase() === month?.toLowerCase()
  );

  if (post) {
    const PostComponent = post.component;
    return (

      <>
        <MobileSideBar />
        <div className="blog-container">
          <div className="blog-content">
            <PostComponent/>
          </div>
        </div>
      </>

    );
  } else {
    return <div>Blog post not found.</div>;
  }
};

export default BlogPost;
