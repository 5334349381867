import React from 'react';

const CalculatorsGraph: React.FC = () => {
  const COLORS = {
    extraLightGray: '#F1F4F9',
    lightGray: '#cfcfd9',
    mediumGray: '#cfcfd9',
    lightBlue: '#B7C8E1',
    darkBlue: '#3E5C76',
    primaryBlue: '#8CA0BA',
    white: '#fff',
    darkBluestroke: '#1D2D44',
  };

  return (
    <svg
      id="fill"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width="200"
      height="200"
      preserveAspectRatio="xMinYMin"
    >
      <rect
        height={48}
        style={{ fill: COLORS.lightGray }}
        width={30}
        x={7}
        y={7}
      />
      <rect
        height={48}
        style={{ fill: COLORS.mediumGray }}
        width={6}
        x={7}
        y={7}
      />
      <rect
        height={48}
        style={{ fill: COLORS.mediumGray }}
        width={24}
        x={17}
        y={11}
      />
      <path
        d="M33,55H25a4,4,0,0,0-4-4h0V19h0a4,4,0,0,0,4-4h8a4,4,0,0,0,4,4h0V51h0A4,4,0,0,0,33,55Z"
        style={{ fill: COLORS.extraLightGray }}
      />
      <circle cx={30} cy={34} r={5} style={{ fill: COLORS.lightGray }} />
      <rect
        height={34}
        style={{ fill: COLORS.lightBlue }}
        width={24}
        x={35}
        y={27}
      />
      <path
        d="M57,27v8A24,24,0,0,1,35,58.911V61H59V27Z"
        style={{ fill: COLORS.darkBlue }}
      />
      <rect
        height={6}
        style={{ fill: COLORS.primaryBlue }}
        width={18}
        x={38}
        y={31}
      />
      <rect
        height={9}
        style={{ fill: COLORS.primaryBlue }}
        width={4}
        x={51}
        y={48}
      />
      <rect
        height={2}
        style={{ fill: COLORS.primaryBlue }}
        width={2}
        x={50}
        y={39}
      />
      <rect
        height={2}
        style={{ fill: COLORS.primaryBlue }}
        width={2}
        x={54}
        y={39}
      />
      <rect
        height={2}
        style={{ fill: COLORS.primaryBlue }}
        width={2}
        x={42}
        y={39}
      />
      <rect
        height={2}
        style={{ fill: COLORS.primaryBlue }}
        width={2}
        x={46}
        y={39}
      />
      <rect
        height={2}
        style={{ fill: COLORS.primaryBlue }}
        width={2}
        x={50}
        y={43}
      />
      <rect
        height={2}
        style={{ fill: COLORS.primaryBlue }}
        width={2}
        x={54}
        y={43}
      />
      <rect
        height={2}
        style={{ fill: COLORS.primaryBlue }}
        width={2}
        x={38}
        y={39}
      />
      <rect
        height={2}
        style={{ fill: COLORS.primaryBlue }}
        width={2}
        x={42}
        y={43}
      />
      <rect
        height={2}
        style={{ fill: COLORS.primaryBlue }}
        width={2}
        x={46}
        y={43}
      />
      <rect
        height={2}
        style={{ fill: COLORS.primaryBlue }}
        width={2}
        x={38}
        y={43}
      />
      <rect
        height={2}
        style={{ fill: COLORS.primaryBlue }}
        width={2}
        x={42}
        y={47}
      />
      <rect
        height={2}
        style={{ fill: COLORS.primaryBlue }}
        width={2}
        x={38}
        y={47}
      />
      <rect
        height={2}
        style={{ fill: COLORS.primaryBlue }}
        width={2}
        x={42}
        y={51}
      />
      <rect
        height={2}
        style={{ fill: COLORS.primaryBlue }}
        width={2}
        x={38}
        y={51}
      />
      <rect
        height={2}
        style={{ fill: COLORS.primaryBlue }}
        width={2}
        x={42}
        y={55}
      />
      <rect
        height={2}
        style={{ fill: COLORS.primaryBlue }}
        width={2}
        x={46}
        y={47}
      />
      <rect
        height={2}
        style={{ fill: COLORS.primaryBlue }}
        width={2}
        x={46}
        y={51}
      />
      <rect
        height={2}
        style={{ fill: COLORS.primaryBlue }}
        width={2}
        x={46}
        y={55}
      />
      <rect
        height={2}
        style={{ fill: COLORS.primaryBlue }}
        width={2}
        x={38}
        y={55}
      />
      <polygon
        points="38 35 38 31 42 31 38 35"
        style={{ fill: COLORS.white }}
      />
      <path
        d="M41,10H38V7a1,1,0,0,0-1-1H7A1,1,0,0,0,6,7V55a1,1,0,0,0,1,1h9v3a1,1,0,0,0,1,1H35V58H18V12H40V27h2V11A1,1,0,0,0,41,10ZM8,8h4V54H8Zm9,2a1,1,0,0,0-1,1V54H14V8H36v2Z"
        style={{ fill: COLORS.darkBluestroke }}
      />{' '}
    </svg>
  );
};

export default CalculatorsGraph;
