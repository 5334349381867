import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Login.css';
import { useNavigate } from 'react-router-dom'; // Import Link from react-router-dom
import { useAuth } from './AuthContext'; // Import the useAuth hook
import { sendBackendRequest } from './../../Services/BackendRequestClient/BackendRequestClient'
import MobileSideBar from './../../components/MobileSideBar/MobileSideBar';

const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError('Invalid email format.');
            return;
        }

        if (password.length < 6) {
            setError('Password must be at least 6 characters long.');
            return;
        }

        setError('');
        setLoading(true);

        try {


            const data = await sendBackendRequest({ rel_endpoint: "/users/login", method: "POST", body: { email, password } })

            setSuccess('Login successful! Redirecting...');

            setEmail('');
            setPassword('');

            login(data.user.username, data.user.email, data.user.id, data.user.created_at);

            
            console.log(email)
            console.log(data.user.created_at)

            setTimeout(() => {
                navigate('/');
            }, 500);

        } catch (err) {
            console.error(err);
            setError('Login failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <MobileSideBar />
            <div className="login-container">
                <h2>Login to Your Account</h2>

                {error && <div className="error-message">{error}</div>}
                {success && <div className="success-message">{success}</div>}

                <form onSubmit={handleLogin} className="login-form">
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            placeholder="Enter your email"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            placeholder="Enter your password"
                        />
                    </div>

                    <button type="submit" className="login-button" disabled={loading}>
                        {loading ? 'Logging in...' : 'Log In'}
                    </button>
                </form>

                {/* Add a link to the signup page */}
                <div className="redirect-signup">
                    <p>Don't have an account? <Link to="/signup">Sign Up</Link></p>
                </div>
            </div>
        </>
    );
};

export default Login;
