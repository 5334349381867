import './Button.css';
import React from 'react';

interface ShareButtonProps {
  text?: string;
  className?: string;
  customUrl?: string;
}

const ShareButton: React.FC<ShareButtonProps> = ({
  text,
  className,
  customUrl,
}) => {
  const urlToShare = customUrl || window.location.href; // Use customUrl if provided, else fall back to current URL

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Check out this website',
          text: 'This is an amazing website I want to share!',
          url: urlToShare,
        });
        console.log('Successfully shared');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      console.log('Share not supported on this browser.');
      alert('Sharing is not supported on this browser.');
    }
  };

  return (
    <button onClick={handleShare} className={`share-button ${className}`}>
      {/* Text next to the icon */}
      {text || 'Share'}
      {/* Share Icon (SVG) */}
      <svg
        fill="#0D1321"
        width="16px" // Set width to 16px
        height="16px" // Set height to 16px
        viewBox="0 0 18 16" // Update the viewBox to match the 16x16 size
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMinYMin"
        className="jam jam-share share-button-icon"
      >
        <path d="M8 3.414v5.642a1 1 0 1 1-2 0V3.414L4.879 4.536A1 1 0 0 1 3.464 3.12L6.293.293a.997.997 0 0 1 1.414 0l2.829 2.828A1 1 0 1 1 9.12 4.536L8 3.414zM3 6a1 1 0 1 1 0 2H2v4h10V8h-1a1 1 0 0 1 0-2h1a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h1z" />
      </svg>
    </button>
  );
};

export default ShareButton;
